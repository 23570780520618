import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "ducks/state";
import { Dialog, DialogProps, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Preferences, SubscriptionOffer } from "@udok/lib/api/models";
import LoadingContainer from "@udok/lib/components/LoadingContainerAutoHeight";
import DialogTitle, {
  DialogTitleProps,
} from "@udok/lib/components/Dialog/Title";
import UdokLogoLarge from "@udok/lib/components/Illustrations/UdokLogoLarge";
import CreateSubscription, {
  CreateSubscriptionProps,
} from "containers/Marketing/CreateSubscription";
import { SubscriptionProperties } from "containers/Marketing/SubscriptionSummary";
import {
  loadSubscriptionOffers,
  fetchCachedUserMe,
  fetchCachedBillingInformation,
  getOneSubscriptionOffers,
} from "ducks/user";
import { loadCreditCards } from "ducks/billing";
import { fetchOneDoctorPreference } from "ducks/doctorPreferences";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      inset: `5% ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`,
      [theme.breakpoints.down("sm")]: {
        inset: theme.spacing(2, 1),
      },
    },
    paperFullScreen: {
      borderTopLeftRadius: theme.spacing(),
      borderTopRightRadius: theme.spacing(),
      [theme.breakpoints.down("sm")]: {
        borderRadius: theme.spacing(),
      },
    },
    dialogTitle: {
      justifyContent: "space-between",
      alignItems: "center",
      "& > *:nth-child(2)": {
        width: "auto",
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
    },
    logo: {
      marginRight: theme.spacing(1),
    },
  })
);

export type SubscriptionDialogProps = {
  open: boolean;
  close?: () => void;
  suofID?: string;
  dialogProps?: Omit<DialogProps, "open" | "aria-labelledby">;
  dialogTitleProps?: Omit<DialogTitleProps, "id" | "onClose">;
} & Omit<CreateSubscriptionProps, "startOffer" | "startScreen">;

function getStartScreen(offer?: SubscriptionOffer) {
  if (!offer) {
    return SubscriptionProperties.offer;
  }
  return offer?.price > 0 && offer?.billingEnabled
    ? SubscriptionProperties.creditCard
    : SubscriptionProperties.confirm;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SubscriptionDialog = (props: SubscriptionDialogProps) => {
  const {
    open,
    close,
    dialogProps,
    dialogTitleProps,
    suofID,
    currentSubscription,
    ...others
  } = props;
  const [loading, setLoading] = React.useState(true);
  const dispatch: AppDispatch = useDispatch();
  const getOffer = React.useCallback(
    getOneSubscriptionOffers({ suofID: suofID ?? "" }),
    [suofID]
  );
  const { offer } = useSelector(getOffer);
  const classes = useStyles();
  const startOffer =
    currentSubscription?.suplID !== offer?.suplID ? offer : undefined;

  React.useEffect(() => {
    if (open) {
      Promise.all([
        dispatch(loadSubscriptionOffers()),
        dispatch(fetchCachedUserMe()),
        dispatch(fetchCachedBillingInformation()),
        dispatch(loadCreditCards()),
        dispatch(
          fetchOneDoctorPreference(Preferences.financesDefaultCreditCard)
        ),
      ]).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  }, [open, dispatch]);

  return (
    <Dialog
      open={open}
      fullScreen
      aria-labelledby="udok-offers-modal"
      {...dialogProps}
      style={{ inset: undefined }}
      TransitionComponent={Transition}
      classes={{
        root: classes.dialog,
        paperFullScreen: classes.paperFullScreen,
      }}
    >
      <DialogTitle
        id="udok-offers-modal"
        onClose={close}
        className={classes.dialogTitle}
        classes={{ title: classes.title }}
        {...dialogTitleProps}
      >
        <UdokLogoLarge width={80} height="auto" className={classes.logo} />
        <span>Assinatura Udok</span>
      </DialogTitle>
      <LoadingContainer loading={loading}>
        <CreateSubscription
          startScreen={getStartScreen(startOffer)}
          startOffer={startOffer}
          currentSubscription={currentSubscription}
          {...others}
        />
      </LoadingContainer>
    </Dialog>
  );
};

export default SubscriptionDialog;
