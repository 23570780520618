import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import { getContrastingColor, debounce } from "@udok/lib/internal/util";
import clsx from "clsx";

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  rootError: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
    borderRadius: theme.spacing(1),
  },
  inputButton: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  colorView: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.neutral.ultralight,
    fill: theme.palette.secondary.lightest,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  fileInputLabel: {
    marginLeft: theme.spacing(2),
    color: theme.palette.neutral.dark,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: "60%",
  },
  fileInputLabelError: {
    color: theme.palette.error.main,
  },
}));

export type ColorPickerProps = {
  id: string;
  onChange?: (color: string) => void;
  value?: string;
  label?: string;
  error?: string;
  classes?: {
    root?: string;
    colorView?: string;
    inputButton?: string;
  };
};

export const ColorPicker = React.forwardRef(
  (props: ColorPickerProps, ref: React.Ref<any>) => {
    const { id, value, label, onChange, error, classes: classesProps } = props;
    const classes = styles(props);

    const handleChange = React.useCallback(
      debounce(
        (color: string) => {
          onChange?.(color);
        },
        500,
        false
      ),
      [onChange]
    );

    return (
      <div
        className={clsx(classes.root, classesProps?.root, {
          [classes.rootError]: Boolean(error),
        })}
      >
        <label htmlFor={id} style={{ width: "100%", height: "100%" }}>
          <TextField
            type="color"
            id={id}
            name={id}
            value={value}
            style={{ display: "none" }}
            onChange={(e) => handleChange(e?.target?.value)}
          />
          <Button
            ref={ref}
            className={clsx(classes.inputButton, classesProps?.inputButton)}
            component="span"
          >
            <div
              className={clsx(classes.colorView, classesProps?.colorView)}
              style={{ backgroundColor: value }}
            >
              <Icons.ColorLens
                style={
                  value
                    ? {
                        color: getContrastingColor(value),
                      }
                    : {}
                }
                fontSize="large"
              />
            </div>
            <Typography
              variant="body2"
              className={clsx(classes.fileInputLabel, {
                [classes.fileInputLabelError]: Boolean(error),
              })}
            >
              <b>{label ?? "Selecione uma cor..."}</b>
              {error ? (
                <FormHelperText error={true}>{error}</FormHelperText>
              ) : null}
            </Typography>
          </Button>
        </label>
      </div>
    );
  }
);

export default ColorPicker;
