import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import { Drug } from "@udok/lib/api/models";

export type DrugListResponse = Array<Drug>;
const makeCanceableDrugFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (
    token: string,
    filter?: {
      searchText?: string;
      limit?: number;
    }
  ) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const f = filter ? toQueryString(filter) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/drugs${f}`, {
        headers: {
          cancelToken: cancel.token,
          Authorization: token,
        },
      })
      .then((r) => {
        return r.data.data.items as DrugListResponse;
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as DrugListResponse;
        }
        const err = deriveErrorMessage(e, "sch1");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchDrugs = makeCanceableDrugFetch();
