import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Schedule,
  Appointment,
  AppointmentStatusResponse,
  Location,
  Clinic,
  Attachment,
  NewAppointmentStatus,
  NewAppointment,
  AppointmentCollectDocument,
  AppointmentCollectDocumentPresentation,
  AppointmentReschedule,
  FilterAppointmentReschedule,
  Healthplan,
  AppointmentStatusData,
  DocumentData,
  AppointmentReturnPending,
  AppointmentPreview,
  ScheduleLock,
  FilterScheduleLock,
  FilterAppointment,
  AppointmentConfirmation,
  ScheduleLockView,
  ScheduleFilter,
  CreateScheduleLockForm,
  FilterHealthPlan,
  AppointmentRescheduleCreate,
  AppointmentCommunication,
  AppointmentDisplaySettings,
} from "@udok/lib/api/models";

export type ScheduleListResponse = Array<Schedule>;
export const fetchSchedules = async (token: string, f?: ScheduleFilter) => {
  let filter = {
    ...f,
    limit: 10000,
  };
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/schedule${toQueryString(filter)}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ScheduleListResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch1");
      return Promise.reject(err);
    });
};

export type ScheduleResponse = Schedule;
export const fetchSchedule = async (token: string, sescID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/schedule/${sescID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch2");
      return Promise.reject(err);
    });
};

export const deleteSchedule = async (token: string, sescID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/schedule/${sescID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch3");
      return Promise.reject(err);
    });
};

export const createSchedule = async (token: string, schedule: Schedule) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/schedule`, schedule, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch4");
      return Promise.reject(new Error(err));
    });
};

export const updateSchedule = async (token: string, schedule: Schedule) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/schedule/${schedule.sescID}`,
      schedule,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ScheduleResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch5");
      return Promise.reject(new Error(err));
    });
};

export type AppointmentListResponse = Array<Appointment>;
export const fetchAppointments = async (
  token: string,
  f?: FilterAppointment
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentListResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch7");
      return Promise.reject(err);
    });
};

export type AppointmentResponse = Appointment;
export const fetchAppointment = async (token: string, appoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as AppointmentResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch8");
      return Promise.reject(err);
    });
};

export type AppointmentEmailRequest = {
  appoID: string;
  sendTarget: "patient" | "doctor";
};

export const sendControlledAppointmentEmail = async (
  token: string,
  mail: AppointmentEmailRequest
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/mailer-controlled-appointment`,
      mail,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch9");
      return Promise.reject(new Error(err));
    });
};

export const sendControlledAppointmentPhone = async (
  token: string,
  mail: AppointmentEmailRequest
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/phone-appointment-invitation`,
      mail,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch25");
      return Promise.reject(new Error(err));
    });
};

export const sendNotificationsPhoneAppointmentConfirmation = async (
  token: string,
  appoID: string
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/notifications/phone-appointment-confirmation`,
      { appoID },
      { headers: { Authorization: token } }
    )
    .then((r) => {
      return r.data.item as string;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch33");
      return Promise.reject(new Error(err));
    });
};

export const sendEditStatusAppointment = async (
  token: string,
  appoID: string,
  appointmentStatus: NewAppointmentStatus
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/status`,
      appointmentStatus,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentStatusResponse;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch14");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentStatus = async (token: string, appoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}/status`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentStatusData[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch14");
      return Promise.reject(new Error(err));
    });
};

export const confirmAppointment = async (
  token: string,
  appoID: string,
  confirmation: AppointmentConfirmation
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/confirm`,
      confirmation,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentConfirmation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch29");
      return Promise.reject(new Error(err));
    });
};

export function fetchLocation(apiToken: string, locaID: string) {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/location/${locaID}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return (r?.data?.data?.item as Location) ?? null;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch15");
      return Promise.reject(err);
    });
}

export function fetchclinic(apiToken: string, clinID: string) {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinics/${clinID}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return (r?.data?.data?.item as Clinic) ?? null;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch16");
      return Promise.reject(err);
    });
}

export const fetchAttachmentsByappoID = (token: string, appoID: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/attachments`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.items as Attachment[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch15");
      return Promise.reject(err);
    });
};

export const sendEditAppointment = async (
  token: string,
  appoID: string,
  obj: {
    markedAt?: string;
    returnVisitEnabled?: boolean;
  }
) => {
  return axios
    .patch(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}`, obj, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Appointment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch17");
      return Promise.reject(err);
    });
};

export const createAppointment = async (
  token: string,
  newAppo: NewAppointment
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/appointments`, newAppo, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Appointment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch19");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentCollectDocument = async (
  token: string,
  apcdID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents/${apcdID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocumentPresentation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch20");
      return Promise.reject(err);
    });
};

export const createAppointmentReschedule = async (
  token: string,
  newSchedule: AppointmentRescheduleCreate
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments-reschedule`,
      newSchedule,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentReschedule;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch21");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentReschedule = async (
  token: string,
  apscID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments-reschedule/${apscID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentReschedule;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch22");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointmentReschedules = async (
  token: string,
  f?: FilterAppointmentReschedule
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments-reschedule${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentReschedule[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch23");
      return Promise.reject(new Error(err));
    });
};

export const updateAppointmentReschedule = (
  apiToken: string,
  apscID: string
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/appointments-reschedule/${apscID}`,
      {},
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentReschedule;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch25");
      return Promise.reject(new Error(err));
    });
};

export const fetchHealthplans = async (f?: FilterHealthPlan) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/health-plan${filter}`)
    .then((r) => {
      return r.data.data.items as Healthplan[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch24");
      return Promise.reject(err);
    });
};

export type NewCollectDocumentForm = {
  appoID: string;
  codoID: string;
  fileID: string;
};
export async function createCollectDocument(
  token: string,
  collect: NewCollectDocumentForm
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents`,
      collect,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch19");
      return Promise.reject(new Error(err));
    });
}

export async function deleteCollectDocument(apiToken: string, claaID: string) {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents/${claaID}`,
      {
        headers: { Authorization: apiToken },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocument;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch20");
      return Promise.reject(new Error(err));
    });
}

export type NewDocumentForm = {
  appoID: string;
  resource: string;
  resourceID: string;
  data?: DocumentData;
};

export function createAppointmentAttachment(
  n: NewDocumentForm,
  apiToken: string
) {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/appointments/${n.appoID}/attachments`,
      n,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as Attachment;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch22");
      return Promise.reject(new Error(err));
    });
}

export async function fetchAppoCollectDocuments(
  apiToken: string,
  apcdID: string
) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointment-collect-documents/${apcdID}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCollectDocumentPresentation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch23");
      return Promise.reject(new Error(err));
    });
}

export type FormData = {
  doteID: string;
  formSchema: {};
  uiSchema: {};
  printSchema?: {};
  data: {};
  initialData?: String;
};

export const createFormData = (apiToken: string, formData: FormData) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/documents`, formData, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.item as DocumentData;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch24");
      return Promise.reject(new Error(err));
    });
};

export const fetchAppointsReturnPending = async (
  token: string,
  filter?: { doctID?: string; patiID?: string; clinID?: string }
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointment-return${f}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as AppointmentReturnPending[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch24");
      return Promise.reject(new Error(err));
    });
};

export const getAppointmentPreview = async (appoID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/appointments/${appoID}/preview`)
    .then((r) => {
      return r.data.data.item as AppointmentPreview;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch25");
      return Promise.reject(new Error(err));
    });
};

export const createScheduleLock = async (
  token: string,
  lock: CreateScheduleLockForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/schedule-lock`, lock, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleLockView;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch26");
      return Promise.reject(new Error(err));
    });
};

export const fetchListScheduleLock = async (
  token: string,
  f?: FilterScheduleLock
) => {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/schedule-lock${filter}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ScheduleLockView[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch27");
      return Promise.reject(new Error(err));
    });
};

export const deleteScheduleLock = async (token: string, scloID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/schedule-lock/${scloID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ScheduleLock;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch28");
      return Promise.reject(err);
    });
};

export const fetchHealthplan = async (heplID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/health-plan/${heplID}`)
    .then((r) => {
      return r.data.data.item as Healthplan;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch30");
      return Promise.reject(err);
    });
};

export async function fetchAppointmentCommunication(
  apiToken: string,
  appoID: string
) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/communication`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as AppointmentCommunication;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch31");
      return Promise.reject(new Error(err));
    });
}

export async function getAppointmentDisplaySettings(appoID: string) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/appointments/${appoID}/display-settings`
    )
    .then((r) => {
      return r.data.data.item as AppointmentDisplaySettings;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch32");
      return Promise.reject(new Error(err));
    });
}
