import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Icons from "@udok/lib/components/Icon";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      width: "auto",
      color: theme.palette.neutral.dark,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    icon: {
      minWidth: 32,
    },
  })
);

export type HeaderTitleContextValue = {
  content: React.ReactNode | React.ReactNodeArray | null;
  setContent: (c: React.ReactNode | React.ReactNodeArray | null) => void;
};
export const HeaderTitleContext = React.createContext<HeaderTitleContextValue>({
  content: null,
  setContent: () => {},
});

export function HeaderTitle({
  children,
}: {
  children: React.ReactNode | React.ReactNodeArray | null;
}) {
  const { setContent } = React.useContext(HeaderTitleContext);
  React.useEffect(() => {
    setContent(children);
  }, [children, setContent]);
  return null;
}

export function HeaderIconTitle({
  title,
  icon,
}: {
  title: string;
  icon: keyof typeof Icons | React.ReactElement<any> | null;
}) {
  const classes = useStyles();
  const Icon: any = typeof icon === "string" && icon ? Icons[icon] : icon;
  return (
    <ListItem component="div" className={classes.listItem}>
      <ListItemIcon className={classes.icon}>
        {typeof icon === "string" ? <Icon /> : Icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
}

export function HeaderTitleRenderer() {
  const { content } = React.useContext(HeaderTitleContext);

  return <>{content}</>;
}

export function HeaderTitleContextContainer(props: {
  children?: React.ReactNode;
}) {
  const [headerTitleContent, setHeaderTitleContent] =
    React.useState<React.ReactNode | React.ReactNodeArray | null>(null);

  return (
    <HeaderTitleContext.Provider
      value={{
        content: headerTitleContent,
        setContent: setHeaderTitleContent,
      }}
    >
      {props?.children}
    </HeaderTitleContext.Provider>
  );
}

export enum ChatQueryPrams {
  openChatWithUserID = "openChatWith",
}
export enum ChatVariant {
  double = "double",
  single = "single",
  wrap = "wrap",
}
export enum ChatMessageSize {
  small = "small",
  large = "large",
}
export enum ChatPosition {
  right = "right",
  left = "left",
}

export type ChatData = {
  userID: string | null;
  appoID?: string;
  apscID?: string;
  megrID?: string;
  variant?: ChatVariant;
  messageSize?: ChatMessageSize;
  chatPosition?: ChatPosition;
  classes?: {
    chatWindow?: string;
  };
  onPressActions?: () => void;
};

export type AppointmentData = {
  appoID: string;
  patiID: string;
  section?: "doctorNote";
};
export type ActiveChatUserContextValue = {
  chat?: ChatData | null;
  pinnedAppointment?: AppointmentData | null;
  openNavigationDrawer?: boolean | null;
  setOpenNavigationDrawer?: (o: boolean) => void;
  setMenuVariant?: (v: "temporary" | "permanent" | "persistent") => void;
  setChat: (c: ChatData | null) => void;
  setPinnedAppointment?: (c: AppointmentData | null) => void;
};
export const ActiveChatUserContext =
  React.createContext<ActiveChatUserContextValue>({
    chat: null,
    pinnedAppointment: null,
    openNavigationDrawer: null,
    setOpenNavigationDrawer: () => {},
    setMenuVariant: () => {},
    setChat: () => {},
    setPinnedAppointment: () => {},
  });

export function ChatContextContainer(props: {
  children?: React.ReactNode;
  value?: Omit<ActiveChatUserContextValue, "chat" | "setChat">;
}) {
  const [activeChat, setActiveChat] = React.useState<ChatData | null>(null);
  const [pinnedAppointment, setPinnedAppointment] =
    React.useState<AppointmentData | null>(null);

  return (
    <ActiveChatUserContext.Provider
      value={{
        chat: activeChat,
        setChat: setActiveChat,
        pinnedAppointment,
        setPinnedAppointment,
        ...(props?.value ?? {}),
      }}
    >
      {props?.children}
    </ActiveChatUserContext.Provider>
  );
}
