import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Patient,
  NonUdokPatient,
  PatientHistory,
  FilterPatientHistory,
  PatientControlledAuthentication,
  PatientContactInformation,
  PatientPlansHistory,
  PatientPersonalInformation,
  PatientWithStats,
  PatientHealthplanCard,
  FilterPatientHealthplanCard,
  PatientCRMInformation,
  PatientCRMInformationForm,
  RecordFlag,
  RecordFlagFilter,
  PatientRecordFlag,
  PatientRecordFlagFilter,
  PatientGovernmentID,
} from "@udok/lib/api/models";

export type PatientFilter = {
  ownPatient?: boolean | undefined;
  keyword?: string[] | undefined;
  "order[desc]"?: "lastActive";
  "order[asc]"?: "lastActive";
  limit?: number | undefined;
  offset?: number | undefined;
};

const makeCanceablePatientsFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (apiToken: string, filter: PatientFilter) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const q = toQueryString(filter);
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/patients${q}`, {
        cancelToken: cancel.token,
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        return r.data.data.items as PatientWithStats[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as PatientWithStats[];
        }
        const err = deriveErrorMessage(e, "pat1");
        return Promise.reject(err);
      });
  };
};

export const fetchPatients = makeCanceablePatientsFetch();

export const fetchPatient = async (token: string, patiID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/patients/${patiID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Patient;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pat2");
      return Promise.reject(err);
    });
};

export function fetchPrescriptionPatients(
  token: string,
  filter?: { name?: string; cutOffDate?: boolean }
) {
  const q = filter ? toQueryString(filter) : "";
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/autocomplete/prescription-patients${q}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.items as NonUdokPatient[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pat3");
      return Promise.reject(new Error(err));
    });
}

const makeCanceablePatientHistoryFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (
    apiToken: string,
    patiID: string,
    filter?: FilterPatientHistory
  ) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const f = filter ? toQueryString(filter) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/patient-history/${patiID}${f}`, {
        cancelToken: cancel.token,
        headers: {
          Authorization: apiToken,
        },
      })
      .then((r) => {
        return r.data.data.items as PatientHistory[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as PatientHistory[];
        }
        const err = deriveErrorMessage(e, "pat4");
        return Promise.reject(err);
      });
  };
};

export const fetchPatientHistory = makeCanceablePatientHistoryFetch();

export async function fetchPatientControlledAuthentication(
  token: string,
  patiID: String
) {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/controlled-authentication`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientControlledAuthentication | null;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pat4");
      return Promise.reject(new Error(err));
    });
}

export const updateContactInformation = async (
  apiToken: string,
  patiID: string,
  formData: PatientContactInformation
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/contact-information`,
      formData,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientContactInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati5");
      return Promise.reject(new Error(err));
    });
};

export const fetchContactInformation = async (
  apiToken: string,
  patiID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/contact-information`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientContactInformation | null | undefined;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati6");
      return Promise.reject(new Error(err));
    });
};

export const updateCRMInformation = async (
  apiToken: string,
  patiID: string,
  formData: PatientCRMInformationForm
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/crm-information`,
      formData,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientCRMInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati15");
      return Promise.reject(new Error(err));
    });
};

export const fetchCRMInformation = async (apiToken: string, patiID: string) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/crm-information`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientCRMInformation | null | undefined;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati16");
      return Promise.reject(new Error(err));
    });
};

export const fetchPatientPlansHistory = async (
  apiToken: string,
  patiID: string
) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/patient-history/${patiID}/plans`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r.data.data.items as PatientPlansHistory[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati7");
      return Promise.reject(new Error(err));
    });
};

export const updatePersonalInformation = async (
  apiToken: string,
  formData: Partial<PatientPersonalInformation>
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/patients/${formData.patiID}/personal-information`,
      formData,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientPersonalInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati8");
      return Promise.reject(new Error(err));
    });
};

export const fetchPersonalInformation = async (
  apiToken: string,
  patiID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/personal-information`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientPersonalInformation | null | undefined;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati9");
      return Promise.reject(new Error(err));
    });
};

export const fetchHealthplanCards = async (
  apiToken: string,
  patiID: string,
  filter?: FilterPatientHealthplanCard
) => {
  const q = filter ? toQueryString(filter) : "";
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/healthplan-cards${q}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.items as PatientHealthplanCard[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati10");
      return Promise.reject(new Error(err));
    });
};

export const createHealthplanCard = async (
  apiToken: string,
  data: Partial<PatientHealthplanCard>
) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/patients/${data.patiID}/healthplan-cards`,
      data,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientHealthplanCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati11");
      return Promise.reject(new Error(err));
    });
};

export const updateHealthplanCard = async (
  apiToken: string,
  data: Partial<PatientHealthplanCard>
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/patients/${data.patiID}/healthplan-cards/${data.phcaID}`,
      data,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientHealthplanCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati12");
      return Promise.reject(new Error(err));
    });
};

export const fetchHealthplanCard = async (
  apiToken: string,
  patiID: string,
  phcaID: number
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/healthplan-cards/${phcaID}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientHealthplanCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati13");
      return Promise.reject(new Error(err));
    });
};

export const deleteHealthplanCard = async (
  apiToken: string,
  patiID: string,
  phcaID: number
) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/patients/${patiID}/healthplan-cards/${phcaID}`,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientHealthplanCard;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati14");
      return Promise.reject(new Error(err));
    });
};

export const fetchRecordFlags = async (
  apiToken: string,
  filter?: RecordFlagFilter
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/record-flag${f}`, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.items as RecordFlag[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati17");
      return Promise.reject(new Error(err));
    });
};

export const fetchRecordFlag = async (apiToken: string, reflID: number) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/record-flag/${reflID}`, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.item as RecordFlag;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati18");
      return Promise.reject(new Error(err));
    });
};

export const createPatientRecordFlag = async (
  apiToken: string,
  data: { patiID?: string; reflID: number }
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/patient-record-flag`, data, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.item as PatientRecordFlag;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati19");
      return Promise.reject(new Error(err));
    });
};

export const fetchPatientRecordFlag = async (
  apiToken: string,
  parfID: number
) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/patient-record-flag/${parfID}`, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.item as PatientRecordFlag;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati20");
      return Promise.reject(new Error(err));
    });
};

export const fetchPatientRecordFlags = async (
  apiToken: string,
  filter?: PatientRecordFlagFilter
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/patient-record-flag${f}`, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.items as PatientRecordFlag[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati21");
      return Promise.reject(new Error(err));
    });
};

export const deletePatientRecordFlag = async (
  apiToken: string,
  parfID: number
) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/patient-record-flag/${parfID}`, {
      headers: { Authorization: apiToken },
    })
    .then((r) => {
      return r.data.data.item as PatientRecordFlag;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati22");
      return Promise.reject(new Error(err));
    });
};

export const patchContactInformation = async (
  apiToken: string,
  formData: Partial<PatientContactInformation>
) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_PATH}/patients/${formData.patiID}/contact-information`,
      formData,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientContactInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati23");
      return Promise.reject(new Error(err));
    });
};

export const patchPersonalInformation = async (
  apiToken: string,
  formData: { patiID: string; name?: string; dateOfBirth?: string }
) => {
  return axios
    .patch(
      `${process.env.REACT_APP_API_PATH}/patients/${formData.patiID}/personal-information`,
      formData,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientPersonalInformation;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati24");
      return Promise.reject(new Error(err));
    });
};

export const updateGovernmentID = async (
  apiToken: string,
  formData: { patiID: string; documentType: string; documentNumber: string }
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/patients/${formData.patiID}/government_id`,
      formData,
      {
        headers: {
          Authorization: apiToken,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as PatientGovernmentID;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pati25");
      return Promise.reject(new Error(err));
    });
};

export const searchPatients = async (token: string, filter: PatientFilter) => {
  const q = toQueryString(filter);
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/patients${q}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as PatientWithStats[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "pat23");
      return Promise.reject(err);
    });
};
