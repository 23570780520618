import React from "react";
import {
  Typography,
  TypographyProps,
  CircularProgress,
  CircularProgressProps,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";

const CircularProgressWithLabel = (
  props: CircularProgressProps & {
    label: number;
    labelProps?: TypographyProps<"div">;
  }
) => {
  const { label, labelProps, ...others } = props;
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...others} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          {...labelProps}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
};

export type CircularTimerProps = {
  countSeconds?: number;
  onEndCount?: () => void;
  labelProps?: TypographyProps<"div">;
} & Omit<CircularProgressProps, "variant" | "value">;

const CircularTimer = (props: CircularTimerProps) => {
  const { countSeconds = 10, onEndCount, ...others } = props;
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [countSeconds]);

  React.useEffect(() => {
    if (progress > countSeconds) {
      onEndCount?.();
    }
  }, [progress, countSeconds, onEndCount]);

  let percentage = (100 * progress) / countSeconds;
  if (percentage <= 0) {
    percentage = 5;
  }
  return progress <= countSeconds ? (
    <CircularProgressWithLabel
      value={percentage}
      label={countSeconds - progress}
      {...others}
    />
  ) : null;
};

export default CircularTimer;
