import React from "react";
import {
  createStyles,
  Theme,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  Button,
  TextField,
  CircularProgress,
  Typography,
  DialogActions,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  useMediaQuery,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import Dialog from "components/Dialog/ResponsiveDialog";
import Uploader from "containers/Uploader/Uploader";
import ImagePicker from "@udok/lib/components/Input/ImagePicker";
import SignatureEditButton from "containers/User/SignatureEditButton";
import { phoneNormalizer, nameNormalize } from "@udok/lib/internal/util";
import { UserProfile, PlanFeaturesPermissions } from "@udok/lib/api/models";
import { ArrowForwardIos, AddBox, CancelOutlined } from "@material-ui/icons";
// @ts-ignore
import { createForm, formShape } from "rc-form";
import { shrinker } from "@udok/lib/internal/util";
import ConfirmNavigationWhenDirty from "@udok/lib/components/ConfirmNavigationWhenDirty";
import MarkdownEditor from "@udok/lib/components/Markdown/MarkdownEditor";
import TextareaHelperText from "@udok/lib/components/Input/TextareaHelperText";
import SocialMediaInput from "@udok/lib/components/SocialMedia/Input";
import ResourceAlertPopover from "containers/LimitedResourceAlert/Popover";
import { createModal } from "@udok/lib/components/Dialog/PromiseDialog";
import ColorPicker from "@udok/lib/components/Input/ColorPicker";
import clsx from "clsx";
const CHARACTER_LIMIT = 120;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formContainer: {
      margin: theme.spacing(0, 2, 0),
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        margin: 0,
      },
    },
    button: {
      margin: theme.spacing(),
      flex: 1,
    },
    large: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    scrollContainer: {
      "& textarea": {
        scrollbarWidth: "thin",
        scrollbarColor: "#dad7d7 #F4F4F4",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F4",
        },
        "&::-webkit-scrollbar": {
          width: 6,
          background: "#F4F4F4",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#dad7d7",
        },
      },
    },
    avatarContainer: {
      display: "flex",
      flexWrap: "wrap",
      marginTop: theme.spacing(2),
      alignItems: "flex-end",
      "&> :first-child": {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
          marginBottom: theme.spacing(1),
        },
      },
      "&> *": {
        flex: 1,
        minWidth: 200,
      },
    },
    markdowLabel: {
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
    markdowContainer: {
      display: "grid",
      border: `1px solid ${theme.palette.neutral.light}`,
      height: "100%",
      borderRadius: 8,
      marginTop: theme.spacing(),
      "&> :first-child": {
        minHeight: 200,
      },
      "& .te-editor": {
        scrollbarWidth: "thin",
        scrollbarColor: "#dad7d7 #F4F4F4",
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#F4F4F4",
        },
        "&::-webkit-scrollbar": {
          width: 6,
          background: "#F4F4F4",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#dad7d7",
        },
      },
    },
    markdowError: {
      borderColor: theme.palette.error.light,
      color: theme.palette.error.main,
    },
    checkboxColor: {
      color: `${theme.palette.primary.light} !important`,
      "&$checked": {
        color: `${theme.palette.primary.light} !important`,
      },
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      margin: theme.spacing(3),
    },
    saveButton: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    curriculumFieldError: {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.error.main,
    },
  })
);

type Field =
  | "avatar"
  | "name"
  | "subSpecialty"
  | "about"
  | "publicEmail"
  | "publicPhone"
  | "experiences"
  | "treatMedicalConditions"
  | "academicFormation"
  | "professionalExperience"
  | "languages"
  | "signatureImage"
  | "profileColor";
export type UserFormProps = {
  onSubmit: (err: any, values: any, form: any) => void;
  initialValues: UserProfile;
  form?: formShape;
  loading: boolean;
  requiredFields?: Field[];
};

const [rendererResourceAlert, promiseResourceAlert] =
  createModal(ResourceAlertPopover);
export const UserForm = (props: UserFormProps) => {
  const { form, onSubmit, initialValues, loading, requiredFields } = props;
  const classes = useStyles();
  const { getFieldDecorator, getFieldError, getFieldValue } = form;
  const theme = useTheme();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields(async (err: any, values: any) => {
      if (!err && !!values?.socialMedia) {
        const viewOffer = await promiseResourceAlert({
          anchorEl: (e.nativeEvent as any).submitter,
          feature: PlanFeaturesPermissions.UnlimitedProfileSettings,
        });
        if (viewOffer) {
          return;
        }
      }
      onSubmit(err, values, form);
    });
  };

  const isRequiredField = (field: Field, def: boolean) => {
    if (!requiredFields) {
      return def;
    }
    return requiredFields.indexOf(field) !== -1;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.formContainer}>
        <div className={classes.avatarContainer}>
          {getFieldDecorator("avatar", {
            rules: [
              {
                required: isRequiredField("avatar", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue: initialValues?.doctor?.avatar,
          })(
            <Uploader>
              <ImagePicker
                htmlFor="profile-form-avatar"
                loading={true}
                label="Foto de perfil"
                baseUrl={process.env.REACT_APP_BASE_PATH + "/files/"}
                type="avatar"
                error={getFieldError("avatar")}
              />
            </Uploader>
          )}
          {getFieldDecorator("profileColor", {
            rules: [
              {
                required: isRequiredField("profileColor", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue:
              initialValues?.doctor?.info?.profileColor ??
              theme.palette.primary.main,
          })(
            <ColorPicker
              id="profile_color"
              label="Cor tema do perfil"
              error={getFieldError("profileColor")}
            />
          )}
        </div>
        <Typography variant="h6" style={{ marginTop: 10 }}>
          Nome:
        </Typography>
        {getFieldDecorator("name", {
          rules: [
            {
              required: isRequiredField("name", true),
              message: "Campo obrigatorio",
            },
          ],
          initialValue: nameNormalize(initialValues?.doctor?.name ?? ""),
          normalize: nameNormalize,
        })(
          <TextField
            variant="outlined"
            fullWidth
            error={Boolean(getFieldError("name"))}
            helperText={getFieldError("name")}
            margin="normal"
            label="Digite seu nome"
            InputLabelProps={{
              ...shrinker(initialValues?.doctor?.name),
            }}
          />
        )}
        <Typography variant="h6" style={{ marginTop: 10 }}>
          Subespecialidade:
        </Typography>
        {getFieldDecorator("subSpecialty", {
          rules: [
            {
              required: isRequiredField("subSpecialty", false),
              message: "Campo obrigatorio",
            },
            (a: any, val: any, cb: (e?: string) => void) => {
              if (!val) {
                cb();
                return;
              }
              if (val?.length > CHARACTER_LIMIT) {
                cb("Número de caracters máximo excedido");
                return;
              }
              cb();
            },
          ],
          initialValue: initialValues?.doctor?.info?.subSpecialty,
        })(
          <TextField
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            label="Subespecialidade"
            helperText={
              <TextareaHelperText
                error={Boolean(getFieldError("subSpecialty"))}
                helperText={getFieldError("subSpecialty")}
                text={getFieldValue("subSpecialty")}
                limit={CHARACTER_LIMIT}
              />
            }
            error={Boolean(getFieldError("subSpecialty"))}
          />
        )}

        <Typography variant="h6" style={{ marginTop: 10 }}>
          Sobre:
        </Typography>
        <InputLabel
          className={clsx(classes.markdowLabel, {
            [classes.markdowError]: getFieldError("about"),
          })}
        >
          Fale sobre você:
        </InputLabel>
        <FormControl
          className={clsx(classes.markdowContainer, {
            [classes.markdowError]: getFieldError("about"),
          })}
          margin="normal"
          fullWidth
          error={getFieldError("about")}
        >
          {getFieldDecorator("about", {
            rules: [
              {
                required: isRequiredField("about", true),
                message: "Campo obrigatorio",
              },
            ],
            initialValue: initialValues?.doctor?.info?.about,
          })(
            <MarkdownEditor
              toolbarItems={["bold", "italic", "strike", "divider", "ul", "ol"]}
            />
          )}
        </FormControl>
        <FormHelperText error={getFieldError("about")}>
          {getFieldError("about")}
        </FormHelperText>

        <Typography variant="h6" style={{ marginTop: 10 }}>
          Informações adicionais:
        </Typography>
        {getFieldDecorator("publicEmail", {
          rules: [
            {
              required: isRequiredField("publicEmail", false),
              message: "Campo obrigatorio",
            },
          ],
          initialValue: initialValues?.doctor?.info?.publicEmail,
        })(
          <TextField
            variant="outlined"
            type="email"
            fullWidth
            error={Boolean(getFieldError("publicEmail"))}
            margin="normal"
            label="Email público"
            helperText={getFieldError("publicEmail") ?? " "}
            InputLabelProps={{
              ...shrinker(initialValues?.doctor?.info?.publicEmail),
            }}
          />
        )}
        {getFieldDecorator("publicPhone", {
          rules: [
            {
              required: isRequiredField("publicPhone", false),
              message: "Campo obrigatorio",
            },
          ],
          normalize: phoneNormalizer,
          initialValue: phoneNormalizer(
            initialValues?.doctor?.info?.publicPhone,
            ""
          ),
        })(
          <TextField
            variant="outlined"
            type="string"
            fullWidth
            placeholder="(__) _____-____"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            label="Telefone público:"
            error={Boolean(getFieldError("publicPhone"))}
            helperText={getFieldError("publicPhone") ?? " "}
          />
        )}
        <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
          Currículo:
        </Typography>
        <List>
          {getFieldDecorator("experiences", {
            rules: [
              {
                required: isRequiredField("experiences", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue:
              initialValues?.doctor?.info?.curriculum?.experiences ?? [],
          })(
            <CurriculumField
              title="Experiência"
              error={getFieldError("experiences")}
            />
          )}
          {getFieldDecorator("treatMedicalConditions", {
            rules: [
              {
                required: isRequiredField("treatMedicalConditions", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue:
              initialValues?.doctor?.info?.curriculum?.treatMedicalConditions,
          })(
            <CurriculumField
              title="Tratar condições médicas"
              error={getFieldError("treatMedicalConditions")}
            />
          )}
          {getFieldDecorator("academicFormation", {
            rules: [
              {
                required: isRequiredField("academicFormation", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue:
              initialValues?.doctor?.info?.curriculum?.academicFormation ?? [],
          })(
            <CurriculumField
              title="Formações"
              error={getFieldError("academicFormation")}
            />
          )}
          {getFieldDecorator("professionalExperience", {
            rules: [
              {
                required: isRequiredField("professionalExperience", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue:
              initialValues?.doctor?.info?.curriculum?.professionalExperience,
          })(
            <CurriculumField
              title="Experiências profissionais"
              error={getFieldError("professionalExperience")}
            />
          )}
          {getFieldDecorator("languages", {
            rules: [
              {
                required: isRequiredField("languages", false),
                message: "Campo obrigatorio",
              },
            ],
            initialValue:
              initialValues?.doctor?.info?.curriculum?.languages ?? [],
          })(
            <CurriculumField
              title="Idiomas"
              error={getFieldError("languages")}
            />
          )}
        </List>
        <Typography variant="h6" style={{ marginTop: 10, marginBottom: 10 }}>
          Assinatura digital
        </Typography>
        {getFieldDecorator("signatureImage", {
          rules: [
            {
              required: isRequiredField("signatureImage", false),
              message: "Campo obrigatorio",
            },
          ],
          initialValue: initialValues?.doctor?.info?.signature,
        })(<SignatureEditButton />)}
        <FormHelperText error={Boolean(getFieldError("signatureImage"))}>
          {getFieldError("signatureImage")}
        </FormHelperText>

        <Typography variant="h6" style={{ marginTop: 10 }}>
          Mídias sociais
        </Typography>
        {getFieldDecorator("socialMedia", {
          initialValue: initialValues?.doctor?.info?.socialMedia,
        })(<SocialMediaInput />)}
      </div>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={loading}
          className={classes.saveButton}
        >
          <span>Salvar</span>
          {loading && <CircularProgress size={24} />}
        </Button>
      </div>
      <ConfirmNavigationWhenDirty form={form} />
      {rendererResourceAlert}
    </form>
  );
};

const CurriculumField = ({
  title,
  value,
  error,
  onChange,
}: {
  title: string;
  value?: string[];
  error?: string;
  onChange?: (value: string[]) => void;
}) => {
  const [view, setView] = React.useState(false);
  const classes = useStyles();

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const limit = isSmallerScreen ? 80 : 170;
  let stringList = value?.join?.(", ") ?? "";
  if (stringList.length > limit) {
    stringList = stringList.substring(0, limit) + " ...";
  }

  return (
    <>
      <ListItem
        button
        onClick={() => setView(true)}
        className={clsx({ [classes.curriculumFieldError]: Boolean(error) })}
      >
        <ListItemText primary={title} secondary={stringList} />
        <ListItemSecondaryAction>
          <ArrowForwardIos style={{ color: "#7d7d7d" }} />
        </ListItemSecondaryAction>
      </ListItem>
      <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
      <ModalList
        title={title}
        open={view}
        list={value ?? []}
        onSave={onChange}
        onClose={() => setView(false)}
      />
    </>
  );
};

const ModalList = ({
  title,
  open,
  list,
  onSave,
  onClose,
}: {
  title: string;
  open: boolean;
  list: string[];
  onSave?: (l: string[]) => void;
  onClose: () => void;
}) => {
  const [newList, setNewList] = React.useState(list);
  const [textvalue, setTextValue] = React.useState("");
  const close = () => {
    setNewList(list);
    onClose();
  };
  return (
    <Dialog
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth="sm"
      onClose={close}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            type="string"
            fullWidth
            value={textvalue}
            onChange={(event) => setTextValue(event.target.value)}
          />
          <IconButton
            color="primary"
            onClick={() => {
              const l = newList ?? [];
              setTextValue("");
              setNewList([...l, textvalue]);
            }}
          >
            <AddBox />
          </IconButton>
        </div>
        <div style={{ maxHeight: 255, overflow: "auto" }}>
          <List dense={true}>
            {newList?.map?.((v, i) => (
              <ListItem key={i + v}>
                <ListItemText primary={v} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      let l = [...newList];
                      l.splice(i, 1);
                      setNewList(l);
                    }}
                  >
                    <CancelOutlined />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onSave?.(newList ?? []);
            close();
          }}
          color="primary"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default createForm()(UserForm);
