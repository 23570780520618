import * as React from "react";
import { Dialog, DialogProps, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const ResponsiveDialog = (props: DialogProps) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <div>
      <Dialog {...props} fullScreen={isSmallerScreen} />
    </div>
  );
};

export default ResponsiveDialog;
