import React from "react";

const root: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 14,
};

const h1: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 48,
  fontWeight: 500,
  lineHeight: 1.167,
  margin: "52px 0 15px 0",
};

const h2: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 40,
  fontWeight: 500,
  lineHeight: 1.2,
  margin: "20px 0 13px 0",
};

const h3: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 32,
  fontWeight: 500,
  lineHeight: 1.167,
  margin: "18px 0 2px",
};

const h4: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 24,
  fontWeight: 500,
  lineHeight: 1.235,
  margin: "10px 0 2px",
};

const h5: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 1.334,
  margin: "9px 0 -4px",
};

const h6: React.CSSProperties = {
  fontFamily: "'Poppins', sans-serif",
  fontSize: 18,
  fontWeight: 500,
  lineHeight: 1.6,
  margin: "9px 0 -4px",
};

const body1: React.CSSProperties = {
  fontFamily: "Inter, sans-serif",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: 1.5,
  margin: "10px 0",
  color: "black",
};

const link: React.CSSProperties = {
  textDecoration: "none",
  pointerEvents: "none",
  color: "initial",
};

export default {
  root,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  body1,
  link,
};
