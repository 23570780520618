import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "ducks/state";
import { getUserMe } from "ducks/user";
import {
  scheduleSelector,
  appointmentSelector,
  scheduleLockSelector,
  createOneSchedule,
  removeOneSchedule,
  createControlledAppointment,
  createPatientForControlledAppointment,
  loadAppointments,
  loadAllClinics,
  fetchCachedAppointmentByMegrID,
  getAppointmentByMegrID,
  loadHealthplans,
  healthplanListView,
  fetchCachedHealthplan,
  healthplanView,
  getSuggestView,
  loadAllSchedules,
  loadAllScheduleLocks,
  createControlledAppointmentView,
  fetchCachedAppointment,
  getAppointmentPreview,
  appointmentPendingReturn,
  loadPendingReturns,
  getOneAppointmentView,
  clinicSelector,
  fetchCachedSchedule,
  fetchCachedAppointmentCommunication,
  getOneAppointmentCommunication,
  calendarFilterSelector,
  getClinicSelector,
  oneScheduleView,
  getDoctorSchedules,
} from "ducks/schedule";
import {
  createOneHealthplanCard,
  deleteOneHealthplanCard,
} from "ducks/patient";
import {
  loadLocations,
  getListlocation,
  locationByLocaID,
} from "ducks/location";
import {
  loadCachedAppointmentType,
  getListAllAppointmentType,
} from "ducks/appointmentType";
import { fetchOneDoctorPreference } from "ducks/doctorPreferences";
import {
  Schedule,
  ScheduleType,
  Appointment,
  Preferences,
  ScheduleLockView,
  NewAppointment,
  InstantAppointmentData,
  FilterAppointment,
  PatientHealthplanCard,
  Location,
  Clinic,
} from "@udok/lib/api/models";
import { generateCalendarEvents } from "@udok/lib/app/schedule";
import { OnboardingPatientForm, OnboardingResponse } from "@udok/lib/api/auth";
import { format, onlyNumbers, AppointmentError } from "@udok/lib/internal/util";
import { DefaultPlan, DefaultAptyID } from "@udok/lib/internal/constants";
import { DayViewAppo } from "@udok/lib/app/appointment";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const useSuggestcalendar = (appoID: string, selectedDay: string) => {
  const appointmentByID = useSelector(appointmentSelector);
  const scheduleByID = useSelector(scheduleSelector);
  const scheduleLockByID = useSelector(scheduleLockSelector);

  const appo = appointmentByID[appoID];
  const schedules = Object.keys(scheduleByID)
    .map((sescID) => scheduleByID[sescID])
    .filter(
      (sch) =>
        sch?.clinID === appo?.clinID &&
        sch?.type === appo?.type &&
        !sch?.deletedAt
    )
    .filter((sch) => !!sch) as Schedule[];
  const appointments = Object.keys(appointmentByID)
    .map((s) => appointmentByID[s])
    .filter((a) => !!a) as Appointment[];
  const blockList = Object.keys(scheduleLockByID)
    .map((id) => scheduleLockByID[id])
    .filter((b) => !!b) as ScheduleLockView[];

  const cal = generateCalendarEvents(
    selectedDay,
    appointments,
    schedules,
    blockList,
    moment().add(15, "minutes").format()
  );
  const calendar = Object.keys(cal).map((k) => cal[k]);
  return [calendar] as [typeof calendar];
};

export const useSuggestScheduleList = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { appointment, blockList, schedules } = useSelector(
    (state: RootState) => getSuggestView(state, { appoID })(state)
  );
  const clinID = React.useMemo(() => appointment?.clinID, [appointment]);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadAllSchedules({ "endAt[gte]": moment().format(), clinID })),
      dispatch(loadAllScheduleLocks()),
    ]).finally(() => setLoading(false));
  }, [clinID, dispatch]);

  return [loading, blockList, schedules] as [
    typeof loading,
    typeof blockList,
    typeof schedules
  ];
};

export const useDefaultAppointmentLocation = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(fetchOneDoctorPreference(Preferences.presDefaultLocation)),
      dispatch(loadLocations()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const getAppointment = React.useCallback(getOneAppointmentView({ appoID }), [
    appoID,
  ]);
  const { appointment: appo } = useSelector(getAppointment);
  const { list, listAllLocation, defaulID } = useSelector(getListlocation);

  let locaID: string = React.useMemo(() => {
    if (!appo?.clinID || !!appo?.locaID) {
      return appo?.locaID || defaulID || list[0]?.locaID;
    }
    const clinicLocations = [...listAllLocation]
      .filter((loc) => loc.clinID === appo?.clinID)
      .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
    return clinicLocations[0]?.locaID;
  }, [appo, list, listAllLocation, defaulID]);

  return [loading, locaID] as [typeof loading, typeof locaID];
};

export const useCreateInstantAppointment = (notifyByPhone?: boolean) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleChangeCardNumber = React.useCallback(
    async (value: {
      patiID: string;
      heplID: string;
      card: { value: string; phcaID?: number };
    }) => {
      const cardNumber = value.card.value;
      const phcaID = value.card?.phcaID;

      const cardData: Partial<PatientHealthplanCard> = {
        patiID: value.patiID,
        heplID: value.heplID,
        cardName: "",
        cardNumber,
      };
      if (phcaID) {
        dispatch(deleteOneHealthplanCard(value.patiID, phcaID));
      }
      return dispatch(createOneHealthplanCard(cardData));
    },
    [dispatch]
  );
  const onCreatePatient = React.useCallback(
    async (values: InstantAppointmentData) => {
      const dob = moment(values?.patientBirthdate, format.DASHUN);
      const pat: OnboardingPatientForm = {
        cpf: onlyNumbers(values?.patientCPF ?? ""),
        dateOfBirth: dob.isValid() ? dob.format(format.DASHUN) : undefined,
        phones: values?.patientPhone
          ? [{ phone: values?.patientPhone, linkedApps: [] }]
          : [],
        email: values?.patientEmail,
        name: values?.patientName ?? "",
      };
      let res: void | OnboardingResponse;
      try {
        res = await dispatch(createPatientForControlledAppointment(pat));
        if (!res) {
          throw new Error("Falha ao criar paciente");
        }
      } catch (e) {
        throw new AppointmentError((e as Error)?.message);
      }
      return res;
    },
    [dispatch]
  );
  const onCreateSchedule = React.useCallback(
    async (values: InstantAppointmentData) => {
      const startAt = moment(values.startAt, format.DATETIMELOCAL);
      const schedule: Partial<Schedule> = {
        type: values.type,
        locaID: values?.location,
        price: 0,
        weekDay: [],
        startAt: startAt.utc().format(format.RFC3349),
        endAt: startAt
          .clone()
          .add(values.appointmentDuration, "minute")
          .utc()
          .format(format.RFC3349),
        appointmentDuration: values.appointmentDuration,
        eventDuration: values.appointmentDuration,
        appointmentOptions: [
          { aptyID: values?.aptyID ?? DefaultAptyID, default: true },
        ],
        marketplaceOffer: false,
        selfService: false,
        healthPlans: [values?.heplID ?? DefaultPlan],
        blockUnpaidAppointment: false,
        weekdayTimezoneOffset: moment().utcOffset() * 60,
        procedures: values?.procedures,
      };
      let res: void | Schedule;
      try {
        res = await dispatch(createOneSchedule(schedule as Schedule, false));
        if (!res) {
          throw new Error("Falha ao criar horário");
        }
      } catch (e) {
        throw new AppointmentError((e as Error)?.message);
      }
      return res;
    },
    [dispatch]
  );
  const onCreateAppointment = React.useCallback(
    (
      values: {
        sescID: string;
        markedAt: string;
        patiID: string;
        heplID: string;
        aptyID: string;
        specID?: number;
        returnForAppoID?: string;
        returnForMarkedAt?: string;
        procedures?: string[];
      },
      force: boolean
    ) => {
      const appointment = {
        sescID: values.sescID,
        markedAt: values.markedAt,
        patiID: values.patiID,
        aptyID: values.aptyID,
        healthplans: [values.heplID],
        invoicePatient: false,
        forceCreate: force,
        specID: values?.specID,
        returnForAppoID: values?.returnForAppoID,
        returnForMarkedAt: values?.returnForMarkedAt,
        procedures: values?.procedures,
      } as NewAppointment;

      return dispatch(
        createControlledAppointment(appointment, undefined, notifyByPhone)
      );
    },
    [dispatch, notifyByPhone]
  );

  const onCreate = React.useCallback(
    async (data: InstantAppointmentData, force: boolean) => {
      setLoading(true);
      try {
        const sch = await onCreateSchedule(data);
        if (!sch) {
          setLoading(false);
          return;
        }
        let patiID = data?.patiID;
        if (!patiID) {
          const p = await onCreatePatient(data);
          if (!p) {
            setLoading(false);
            return;
          }
          patiID = p.user.patient?.patiID;
        }
        const appoVal = {
          sescID: sch.sescID,
          patiID: patiID ?? "",
          markedAt: sch.startAt,
          aptyID: sch?.appointmentOptions?.[0]?.aptyID ?? DefaultAptyID,
          heplID: data?.heplID ?? DefaultPlan,
          specID: data?.specID,
          returnForAppoID: data?.returnForAppoID,
          returnForMarkedAt: data?.returnForMarkedAt,
          procedures: sch.procedures,
        };
        let resp: Appointment | undefined;
        await onCreateAppointment(appoVal, force)
          .then((r) => {
            resp = r;
            if (
              patiID &&
              data?.heplID &&
              data?.heplID !== DefaultPlan &&
              data?.healthplanCardNumber?.value
            ) {
              handleChangeCardNumber({
                patiID,
                heplID: data?.heplID,
                card: data?.healthplanCardNumber,
              });
            }
          })
          .catch((e) => {
            let message = "Falha ao criar o agendamento";
            if (e.message.indexOf("appointment: schedule unavailable") > -1) {
              message = "Horário indisponível.";
            }
            throw new AppointmentError(message, { patiID });
          })
          .finally(() => {
            dispatch(removeOneSchedule(sch.sescID, false)).catch(console.warn);
          });
        setLoading(false);
        return resp;
      } catch (e) {
        setLoading(false);
        throw e;
      }
    },
    [
      onCreatePatient,
      onCreateSchedule,
      onCreateAppointment,
      handleChangeCardNumber,
      dispatch,
    ]
  );

  return [loading, onCreate] as [typeof loading, typeof onCreate];
};

export const useFetchAppointmentData = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadAllClinics()),
      dispatch(loadLocations()),
    ]).finally(() => setLoading(false));
  }, [dispatch]);

  const onLoadAppointment = React.useCallback(
    (f?: FilterAppointment) => {
      setLoading(true);
      return dispatch(loadAppointments(f)).finally(() => setLoading(false));
    },
    [dispatch]
  );

  return [onLoadAppointment, loading] as [
    typeof onLoadAppointment,
    typeof loading
  ];
};

export const useListScheduleLocks = (ids: string[]) => {
  const scheduleLockByID = useSelector(scheduleLockSelector);
  const filtredIds = Object.keys(scheduleLockByID).filter(
    (id) => ids.indexOf(id) !== -1
  );
  const blockList = filtredIds
    .map((id) => scheduleLockByID[id])
    .filter((b) => !!b) as ScheduleLockView[];

  return [blockList] as [typeof blockList];
};

export const useGetAppointmentByMegrID = (megrID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedAppointmentByMegrID(megrID)).finally(() =>
      setLoading(false)
    );
  }, [dispatch, megrID]);

  const { appointment } = useSelector((state: RootState) =>
    getAppointmentByMegrID(state, { megrID })(state)
  );

  return [loading, appointment] as [typeof loading, typeof appointment];
};

export const useListHealthplans = () => {
  const [loading, setLoading] = React.useState(false);

  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector(getUserMe);
  const doctID = currentUser?.doctor?.doctID;
  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      dispatch(loadHealthplans()),
      ...(doctID ? [dispatch(loadHealthplans({ doctID }))] : []),
    ]).finally(() => setLoading(false));
  }, [dispatch, doctID]);
  const { list, doctorPlans } = useSelector(healthplanListView);

  return [loading, list, doctorPlans] as [
    typeof loading,
    typeof list,
    typeof doctorPlans
  ];
};

export const useGetHealthplan = (heplID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchCachedHealthplan(heplID)).finally(() => setLoading(false));
  }, [heplID, dispatch]);

  const { helthplan } = useSelector((state: RootState) =>
    healthplanView(state, { heplID })(state)
  );

  return [loading, helthplan] as [typeof loading, typeof helthplan];
};

export const useCreateControlledAppointmentView = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadAllSchedules({ "endAt[gte]": moment().format() })).finally(
      () => setLoading(false)
    );
  }, [dispatch]);

  const { blockList, schedules } = useSelector(createControlledAppointmentView);
  return [loading, blockList, schedules] as [
    typeof loading,
    typeof blockList,
    typeof schedules
  ];
};

export const useGetAppointment = (appoID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (appoID) {
      setLoading(true);
      dispatch(fetchCachedAppointment(appoID)).finally(() => setLoading(false));
    }
  }, [dispatch, appoID]);

  const getAppointment = React.useCallback(
    getAppointmentPreview({ appoID: appoID ?? "" }),
    [appoID]
  );
  const { appointment } = useSelector(getAppointment);

  return [loading, appointment] as [typeof loading, typeof appointment];
};

export const useGetAppointmentPendingReturn = (patiID?: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (patiID) {
      setLoading(true);
      dispatch(loadPendingReturns(patiID)).finally(() => setLoading(false));
    }
  }, [patiID, dispatch]);

  const getPendingReturn = React.useCallback(
    appointmentPendingReturn({ patiID: patiID ?? "" }),
    [patiID]
  );

  const { patientPendingReturn } = useSelector(getPendingReturn);

  return [loading, patientPendingReturn] as [
    typeof loading,
    typeof patientPendingReturn
  ];
};

export type ScheduleListItem = Schedule & {
  locationName: string;
  clinicName: string;
};

export const useSchedulesPresentation = () => {
  const locationByID = useSelector(locationByLocaID);
  const clinicByID = useSelector(clinicSelector);
  const scheduleByID = useSelector(scheduleSelector);

  const list = Object.keys(scheduleByID)
    .map((sescID) => {
      const shedule = scheduleByID[sescID];
      const clinic = shedule?.clinID ? clinicByID[shedule.clinID] : undefined;
      const loc = shedule?.locaID ? locationByID[shedule.locaID] : undefined;
      let locationName = "Plataforma Udok";
      if (shedule?.type !== ScheduleType.virtual) {
        locationName = loc?.name ? loc.name : "";
      }
      return {
        ...shedule,
        locationName,
        clinicName: clinic?.name ?? "",
      } as ScheduleListItem;
    })
    .filter(
      (sch) =>
        !sch.deletedAt &&
        !moment(sch.endAt).isBefore(moment().subtract(2, "days"), "day")
    )
    .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

  return [list] as [typeof list];
};

export const useScheduleAppointmentTypes = (sescID: string) => {
  const [loading, setLoading] = React.useState(false);
  const [aptyIDs, setAptyID] = React.useState<string[]>([]);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (sescID) {
      setLoading(true);
      dispatch(fetchCachedSchedule(sescID))
        .then(async (r) => {
          await Promise.all(
            (r?.appointmentOptions ?? []).map((opt) => {
              setAptyID((ids) => [...ids, opt.aptyID]);
              return dispatch(loadCachedAppointmentType(opt.aptyID));
            })
          );
        })
        .finally(() => setLoading(false));
    }
  }, [dispatch, sescID]);

  const { list } = useSelector(getListAllAppointmentType);
  const listItens = list.filter((l) => aptyIDs?.indexOf(l.aptyID) !== -1);
  return [loading, listItens] as [typeof loading, typeof listItens];
};

export const useGetAppointmentCommunication = (appoID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (appoID) {
      setLoading(true);
      dispatch(fetchCachedAppointmentCommunication(appoID)).finally(() =>
        setLoading(false)
      );
    }
  }, [dispatch, appoID]);

  const getCommunication = React.useCallback(
    getOneAppointmentCommunication({ appoID }),
    [appoID]
  );
  const { communication } = useSelector(getCommunication);

  return [loading, communication] as [typeof loading, typeof communication];
};

export const useAppointmentDayView = (
  day: string,
  excludeStatus?: string[]
) => {
  const appointmentByID = useSelector(appointmentSelector);
  const locationByID = useSelector(locationByLocaID);
  const { clinByID } = useSelector(getClinicSelector);
  const filter = useSelector(calendarFilterSelector);

  const filteredList = Object.keys(appointmentByID)
    .map((s) => appointmentByID[s] as Appointment)
    .filter((a) => {
      if (a.readonly) {
        return false;
      }
      if (!a?.markedAt || moment(a.markedAt).format(format.SPTBR) !== day) {
        return false;
      }
      if (a.appoID === null) {
        return false;
      }
      if ((excludeStatus?.indexOf?.(a.status) ?? -1) > -1) {
        return false;
      }
      if (filter?.patiID && filter.patiID !== a.patiID) {
        return false;
      }
      return true;
    });
  const events: DayViewAppo[] = filteredList.map((a) => {
    const locaID = a?.locaID ?? "";
    const clinID = a?.clinID ?? "";
    const locationInfo = locaID
      ? (locationByID[locaID] as Location)
      : undefined;
    const clinicInfo = clinID ? (clinByID[clinID] as Clinic) : undefined;
    return {
      appointment: a,
      location: locationInfo,
      clinic: clinicInfo,
    } as DayViewAppo;
  });
  return [events] as [typeof events];
};

export const useGetOneSchedule = (sescID: string) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    if (sescID) {
      setLoading(true);
      dispatch(fetchCachedSchedule(sescID)).finally(() => setLoading(false));
    }
  }, [dispatch, sescID]);

  const getSchedule = React.useCallback(oneScheduleView({ sescID }), [sescID]);
  const { schedule, clinic, location } = useSelector(getSchedule);

  return [loading, schedule, clinic, location] as [
    typeof loading,
    typeof schedule,
    typeof clinic,
    typeof location
  ];
};

export const useGetDoctorSchedules = () => {
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);
    dispatch(loadAllSchedules()).finally(() => setLoading(false));
  }, [dispatch]);

  const { list } = useSelector(getDoctorSchedules);
  return [loading, list] as [typeof loading, typeof list];
};
