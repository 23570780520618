import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "ducks/state";
import Form, { FormDisabledType, TypeHour } from "containers/Schedule/Form";
import { Schedule, ScheduleType } from "@udok/lib/api/models";
import {
  formatMoneyInFloat,
  format,
  formatOffset,
} from "@udok/lib/internal/util";
import { updateOneSchedule } from "ducks/schedule";
import { useGetOneSchedule } from "hooks/schedule";
import { createModal } from "components/Dialog/PromiseDialog";
import DialogSchedulePlans from "containers/SchedulePlans";
import { FutureInfiniteDate } from "@udok/lib/internal/constants";
import { LinearProgress } from "@material-ui/core";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

const [rendererDialog, promiseDialog] = createModal(DialogSchedulePlans);

export type ScheduleEditProps = {
  sescID: string;
  onSubmit?: () => void;
};

export const ScheduleEdit = (props: ScheduleEditProps) => {
  const { onSubmit, sescID } = props;
  const [load, schedule] = useGetOneSchedule(sescID);
  const [loading, setLoading] = React.useState(false);
  const dispatch: AppDispatch = useDispatch();

  const openDialog = async (
    locaID: string,
    type: string,
    healthPlans: string[],
    locationPlans: string[]
  ) => {
    if (schedule?.locaID === locaID) {
      return;
    }
    if (type === ScheduleType.place && locationPlans.length > 0) {
      const extraPlans = healthPlans.filter(
        (v: string) => locationPlans?.indexOf?.(v) === -1
      );
      if (extraPlans.length > 0) {
        await promiseDialog({ locaID, extraPlans });
      }
    }
  };

  const handleSubmit = (err: any, values: any, form: any) => {
    if (err) {
      return;
    }
    const offset =
      (values?.weekdayTimezoneOffset as number | undefined) ??
      moment().utcOffset() * 60;
    const formatedOffset = formatOffset(offset);
    if (values.typeHour === TypeHour.date) {
      values = {
        ...values,
        startAt: moment(
          `${values.startDate} ${values.startAt}${formatedOffset}`
        ),
        weekDay: [],
      };
    } else {
      const startDate = moment(values.startDate);
      const now = startDate.isValid()
        ? startDate.format(format.DASHUN)
        : moment().format(format.DASHUN);

      values = {
        ...values,
        startAt: moment(`${now} ${values.startAt}${formatedOffset}`),
      };
    }
    const aptyIDList: string[] = values.aptyIDList ?? [];
    const appointmentOptions = aptyIDList.map((v) => {
      return {
        aptyID: v,
        default: v === values.defaultOption,
      };
    });
    const endHour = moment(values.startAt)
      .add(values.eventDuration, "minutes")
      .format(format.TIME24H);
    const endDate = values?.endDate
      ? moment(`${values.endDate} ${endHour}`, format.DATEHOUR)
      : undefined;

    const schedule: Partial<Schedule> = {
      price: values.price ? formatMoneyInFloat(values.price) : 0,
      weekDay: values.weekDay,
      appointmentDuration: parseInt(values.appointmentDuration),
      locaID: values?.location,
      startAt: values.startAt.utc().format(format.RFC3349),
      endAt:
        endDate && endDate.isValid()
          ? endDate.utc().format(format.RFC3349)
          : undefined,
      sescID,
      appointmentOptions,
      selfService: values.selfService,
      marketplaceOffer: values.marketplaceOffer,
      healthPlans: values.healthPlans,
      blockUnpaidAppointment: values.blockUnpaidAppointment,
      eventDuration: values.eventDuration,
      weekdayTimezoneOffset: values?.weekdayTimezoneOffset,
      procedures: values?.exprID ? [values.exprID] : undefined,
      simultaneousAppointments: values?.simultaneousAppointments,
    };
    const cancelAfterMinutes = parseInt(values.cancelAfterMinutes);
    if (schedule?.marketplaceOffer && !isNaN(cancelAfterMinutes)) {
      schedule.cancelAfterMinutes = cancelAfterMinutes;
    }
    setLoading(true);
    dispatch(updateOneSchedule(schedule as Schedule))
      .then(() => {
        openDialog(
          values.location,
          values.type,
          values.healthPlans,
          values.locationPlans
        ).finally(onSubmit);
      })
      .catch(console.warn)
      .finally(() => {
        setLoading(false);
      });
  };

  const offset = Math.floor((schedule?.weekdayTimezoneOffset ?? -10800) / 3600);
  const startHour = moment
    .utc(schedule?.startAt)
    .utcOffset(offset)
    .format(format.TIME24H);
  const endHour = moment(startHour, format.TIME24H)
    .add(schedule?.eventDuration, "minutes")
    .format(format.TIME24H);
  const endDate = moment(schedule?.endAt).local().format(format.DASHUN);

  const initialValues = {
    ...schedule,
    startAt: startHour,
    endAt: endHour,
    startDate: moment.utc(schedule?.startAt).local().format(format.DASHUN),
    endDate: endDate !== FutureInfiniteDate ? endDate : undefined,
    addPeriod: endDate !== FutureInfiniteDate,
  };
  const isClinc = Boolean(schedule?.clinID);

  return (
    <>
      {(loading || load) && <LinearProgress color="secondary" />}
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={loading}
        disabledType={
          isClinc ? FormDisabledType.clinic : FormDisabledType.update
        }
      />
      {rendererDialog}
    </>
  );
};

export default ScheduleEdit;
