import React from "react";
import {
  FormControlLabel,
  Switch,
  SwitchProps,
  FormHelperText,
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switchError: {
      color: red[300],
      "&$checked": {
        color: red[500],
      },
      "&$checked + $track": {
        backgroundColor: red[500],
      },
    },
  })
);

export type SwitchFieldProps = {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  value?: boolean;
  label: string;
  error?: boolean;
  helperText?: string;
} & SwitchProps;

const SwitchField = React.forwardRef(
  (props: SwitchFieldProps, ref: React.Ref<any>) => {
    const classes = useStyles();
    return (
      <>
        <FormControlLabel
          ref={ref}
          control={
            <Switch
              checked={props.value}
              onChange={props?.onChange}
              {...props}
              color={props.error ? "default" : props.color}
              classes={{
                ...props?.classes,
                switchBase: clsx(
                  { [classes.switchError]: props.error },
                  props?.classes?.switchBase
                ),
              }}
            />
          }
          label={props.label}
        />
        <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
      </>
    );
  }
);

export default SwitchField;
