import { RootState, AppThunk } from "ducks/state";
import { createSelector } from "reselect";
import { newNotification } from "./notification";
import { hen, Hen } from "@udok/lib/internal/store";
import {
  ExamProcedure,
  ExamProcedureFilter,
  Tuss,
  FilterTussList,
} from "@udok/lib/api/models";
import {
  searchProcedures,
  fetchProcedure,
  registerTussUsage,
  fetchAllTuss,
  fetchRecentTuss,
} from "@udok/lib/api/procedure";
import { getToken, UNAUTHORIZED } from "./auth";

export type InitialState = {
  proceduresByID: { [exprID: string]: ExamProcedure | undefined };
  filteredProcedures: string[];
  tussSearch: Tuss[];
  tussRecent: Tuss[];
};

// Reducers
const initialState: InitialState = {
  proceduresByID: {},
  filteredProcedures: [],
  tussSearch: [],
  tussRecent: [],
};

class Procedures extends Hen<InitialState> {
  listProceduresLoaded(list: ExamProcedure[]) {
    this.state.filteredProcedures = list.map((p) => {
      this.state.proceduresByID[p.exprID] = p;
      return p.exprID;
    });
  }
  procedureLoaded(pr: ExamProcedure) {
    this.state.proceduresByID[pr.exprID] = pr;
  }
  loadTussSearch(tuss: Tuss[]) {
    this.state.tussSearch = tuss;
  }
  loadTussRecent(tuss: Tuss[]) {
    this.state.tussRecent = tuss;
  }
  clearTussSearch() {
    this.state.tussSearch = [];
  }
}

export const [Reducer, actions] = hen(new Procedures(initialState), {
  [UNAUTHORIZED]: () => {
    return initialState;
  },
});

// Selectors
export const proceduresSelector = (state: RootState) =>
  state.procedure.proceduresByID;
const filteredProceduresSelector = (state: RootState) =>
  state.procedure.filteredProcedures;
const tussSearchRepository = (state: RootState) => state.procedure.tussSearch;
const tussRecentRepository = (state: RootState) => state.procedure.tussRecent;

export const searchProceduresListView = createSelector(
  [proceduresSelector, filteredProceduresSelector],
  (proceduresByID, filtered) => {
    const allProcedures = Object.keys(proceduresByID)
      .map((id) => proceduresByID[id])
      .filter((b) => !!b) as ExamProcedure[];
    const filteredProcedures = filtered
      .map((id) => proceduresByID[id])
      .filter((b) => !!b) as ExamProcedure[];
    return {
      allProcedures,
      filteredProcedures,
    };
  }
);

export const getOneProcedureView = (
  state: RootState,
  props: { exprID: string }
) =>
  createSelector([proceduresSelector], (proceduresByID) => {
    return {
      procedure: proceduresByID[props.exprID],
    };
  });

export const getProceduresByID = createSelector(
  [proceduresSelector],
  (proceduresByID) => {
    return {
      proceduresByID,
    };
  }
);

export const getTussSearchView = createSelector(
  [tussSearchRepository, tussRecentRepository],
  (search, recent) => {
    return {
      listSearch: search ?? [],
      listRecent: recent ?? [],
    };
  }
);

// Actions
export function searchListProcedures(
  f?: ExamProcedureFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const fil: ExamProcedureFilter = {
      limit: 100,
      ...f,
    };
    return searchProcedures(fil)
      .then((r) => {
        dispatch(actions.listProceduresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function searchScheduleProcedures(
  f?: ExamProcedureFilter
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const doctID = t.token.payload.doctID;
    const fil: ExamProcedureFilter = {
      limit: 100,
      doctID,
      ...f,
    };
    return searchProcedures(fil)
      .then((r) => {
        dispatch(actions.listProceduresLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function loadProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    return fetchProcedure(exprID)
      .then((r) => {
        dispatch(actions.procedureLoaded(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
      });
  };
}

export function fetchCachedProcedure(exprID: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const p = state.procedure.proceduresByID;
    if (Boolean(p[exprID])) {
      return Promise.resolve();
    }

    return dispatch(loadProcedure(exprID));
  };
}

export function searchTuss(filter: FilterTussList): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchAllTuss(apiToken, filter)
      .then((r) => {
        dispatch(actions.loadTussSearch(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function loadRecentTuss(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return fetchRecentTuss(apiToken)
      .then((r) => {
        dispatch(actions.loadTussRecent(r));
      })
      .catch((e) => {
        dispatch(
          newNotification("general", {
            status: "error",
            message: e.message,
          })
        );
        throw e;
      });
  };
}

export function addTussUsage(tussCode: string): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const state = getState();
    const t = getToken(state);
    const apiToken = "Bearer " + t.token.raw;
    return registerTussUsage(apiToken, tussCode)
      .then(() => {})
      .catch(() => {});
  };
}

export function clearSearchTuss(): AppThunk<Promise<void>> {
  return async (dispatch) => {
    dispatch(actions.clearTussSearch());
  };
}
