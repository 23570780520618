import React from "react";
import { AppointmentType } from "@udok/lib/api/models";
import {
  getListAppointmentType,
  loadAppointmentTypes,
} from "ducks/appointmentType";
import { AppDispatch } from "ducks/state";
import { useScheduleAppointmentTypes } from "hooks/schedule";
import Selector, { SelectorProps } from "@udok/lib/components/Selector";
import { DefaultAptyID } from "@udok/lib/internal/constants";
import { useDispatch, useSelector } from "react-redux";

export type SelectAppoTypeProps = {
  onChange?: (id: string | undefined) => void;
  list: AppointmentType[];
  filter?: string[];
} & Omit<SelectorProps, "onChange" | "list">;

const SelectAppoType = React.forwardRef(
  (props: SelectAppoTypeProps, ref: React.Ref<any>) => {
    const { onChange, list, multiple, value, filter, ...others } = props;

    const val = multiple
      ? Array.isArray(value)
        ? value
        : value
        ? [value]
        : []
      : String(value ?? "");
    let listItens = list.map((l) => {
      return {
        label: l.name,
        value: l.aptyID,
      };
    });

    if (!listItens.find((li) => li.value === DefaultAptyID)) {
      listItens = [
        ...listItens,
        { label: "Padrão do sistema", value: DefaultAptyID },
      ];
    }

    if ((filter ?? []).length > 0) {
      listItens = listItens.filter((l) => filter?.indexOf(l.value) !== -1);
    }

    return (
      <Selector
        ref={ref}
        multiple={multiple}
        list={listItens}
        value={val}
        onChange={onChange}
        {...others}
      />
    );
  }
);

const ConnectSelectAppoType = React.forwardRef(
  (props: Omit<SelectAppoTypeProps, "list">, ref: React.Ref<any>) => {
    const [loading, setLoading] = React.useState(false);
    const dispatch: AppDispatch = useDispatch();
    const { list } = useSelector(getListAppointmentType);

    React.useEffect(() => {
      setLoading(true);
      dispatch(loadAppointmentTypes())
        .catch(console.warn)
        .finally(() => {
          setLoading(false);
        });
    }, [dispatch]);

    return (
      <SelectAppoType
        ref={ref}
        list={list}
        {...props}
        loading={props?.loading || loading}
      />
    );
  }
);

export default ConnectSelectAppoType;

export type ScheduleAppointmentTypesProps = {
  sescID: string;
} & Omit<SelectAppoTypeProps, "list" | "filter">;

export const SelectScheduleAppointmentTypes = React.forwardRef(
  (props: ScheduleAppointmentTypesProps, ref: React.Ref<any>) => {
    const { sescID, ...others } = props;
    const [loading, listItens] = useScheduleAppointmentTypes(sescID);

    return (
      <SelectAppoType
        ref={ref}
        list={listItens}
        loading={others?.loading || loading}
        {...others}
      />
    );
  }
);
