import React from "react";
import { Location } from "@udok/lib/api/models";
import { useHistory } from "react-router";
import { Typography, Button } from "@material-ui/core";
import Selector, { SelectorProps } from "@udok/lib/components/Selector";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useListLocations } from "hooks/location";

export enum Return {
  id = "id",
  location = "location",
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerEmpty: {
      marginBottom: theme.spacing(2),
    },
  })
);

export type SelectDoctorsProps = {
  onChange?: (value?: any) => void;
  value?: string | Location;
  listAll?: boolean;
  returnType?: "id" | "location";
  emptyListAlert?: React.ReactNode;
} & Omit<
  SelectorProps,
  "onChange" | "value" | "placeholder" | "list" | "loading"
>;

const SelectLocales = React.forwardRef(
  (props: SelectDoctorsProps, ref: React.Ref<any>) => {
    const {
      onChange,
      listAll = false,
      value,
      returnType = Return.id,
      emptyListAlert = null,
      ...others
    } = props;
    const val = typeof value === "string" ? value : value?.locaID ?? undefined;
    const [loading, listLocation, clinicByID] = useListLocations(listAll, val);
    const classes = useStyles();
    const history = useHistory();

    const formatValue =
      typeof value === "string" || Array.isArray(value)
        ? (value as string | string[])
        : value?.locaID;

    const selectLocation = (value?: string | string[]) => {
      if (returnType === Return.location && !Array.isArray(value)) {
        const selectLoca = listLocation.filter((l) => l.locaID === value);
        onChange?.(selectLoca[0]);
        return;
      }
      onChange?.(value);
    };

    const listItens = listLocation.map((l) => {
      let clinicName = "";
      if (l?.clinID) {
        const clinic = clinicByID[l.clinID];
        clinicName = clinic?.name ? ` ( ${clinic?.name} )` : "( Clinica )";
      }

      return {
        label: `${l.name}${clinicName}`,
        value: l.locaID,
      };
    });

    const placeholder =
      !listLocation || listLocation?.length === 0 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography color="primary">Nenhum local cadastrado</Typography>
          <Button
            onClick={() => history.push(`/admin/locations/create`)}
            color="primary"
            variant="contained"
            disabled={false}
          >
            Gerenciar locais
          </Button>
        </div>
      ) : undefined;

    const openEmptyListAlert =
      listItens.length === 0 && emptyListAlert && !loading;
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Selector
          ref={ref}
          fullWidth
          list={listItens}
          loading={loading}
          value={formatValue}
          placeholder={placeholder}
          onChange={selectLocation}
          style={openEmptyListAlert ? { marginBottom: 0 } : undefined}
          {...others}
          errorMessage={!openEmptyListAlert ? others.errorMessage : undefined}
        />
        {openEmptyListAlert ? (
          <div className={classes.containerEmpty}>{emptyListAlert}</div>
        ) : null}
      </div>
    );
  }
);

export default SelectLocales;
