import React from "react";
import { Prompt } from "react-router";

const OnBeforeUnload = ({
  disabled,
  message,
  onExit,
}: {
  disabled?: boolean;
  message: string;
  onExit?: () => void;
}) => {
  React.useEffect(() => {
    if (disabled) {
      return;
    }
    window.onbeforeunload = () => {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
      onExit?.();
    };
  }, [disabled, onExit]);

  return <Prompt when={!disabled} message={message} />;
};

export default OnBeforeUnload;
