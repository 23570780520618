import React from "react";
import { useSelector } from "react-redux";
import { getToken } from "ducks/auth";
import { useHistory, useLocation } from "react-router";
import { toQueryString } from "@udok/lib/internal/util";
import { useLocalConfig, SettingsKeys } from "@udok/lib/hooks/localConfig";

import moment from "moment";
moment.locale("pt-br");

export function useOnboardingRedirectOnRendering() {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const otp = query.get("otp");
  const offer = query.get("offer");
  const { token } = useSelector(getToken);

  React.useEffect(() => {
    const exp = token?.payload?.exp ?? 0;
    if (moment.utc().unix() < exp || otp) {
      const path = offer
        ? `/admin/finances/subscription/change${toQueryString({ otp, offer })}`
        : `/admin${toQueryString({ otp })}`;
      const confirm = window.confirm(
        "Você já está logado!\nTe redirecionaremos para o sistema."
      );
      if (confirm) {
        history.replace(path);
      }
    }
    // eslint-disable-next-line
  }, [otp, history, offer]);
}

export function useGoToStartScreen() {
  const history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [setting, setLocalConfig] = useLocalConfig(
    SettingsKeys.RouterQueryString
  );
  const [offer] = React.useState(query.get("offer") ?? setting?.["offer"]);

  const goToStartScreen = React.useCallback(() => {
    if (!offer) {
      history.push("/admin");
      return;
    }
    setLocalConfig({ offer: undefined });
    history.push(
      `/admin/finances/subscription/change${toQueryString({ offer })}`
    );
  }, [history, offer, setLocalConfig]);

  return [goToStartScreen] as [typeof goToStartScreen];
}
