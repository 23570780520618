import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  DoctorPreference,
  HealthcareLicense,
  Doctor,
  DoctorPublicProfile,
  DoctorPreferenceForm,
  ClinicDoctor,
  ClinicDoctorListFilter,
  DoctorClinicPatient,
  DoctorLicense,
} from "@udok/lib/api/models";

export const createDoctorPreference = async (
  token: string,
  pref: DoctorPreferenceForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/doctor-preferences`, pref, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as DoctorPreference;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "Dp1");
      return Promise.reject(new Error(err));
    });
};

export const fetchDoctorPreference = async (token: string, prefID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctor-preferences/${prefID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as DoctorPreference;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "Dp2");
      return Promise.reject(new Error(err));
    });
};

export const fetchListDoctorPreference = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctor-preferences`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as DoctorPreference[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "Dp3");
      return Promise.reject(new Error(err));
    });
};

export const fetchLicenseTypes = async () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_PATH}/healthcare-licenses`)
    .then((r) => {
      return r.data.data.items as HealthcareLicense[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "lct1");
      return Promise.reject(err);
    });
};

export const fetchDoctor = async (token: string, doctID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctors/${doctID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Doctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc1");
      return Promise.reject(new Error(err));
    });
};

export type FilterDoctorList = {
  listAll?: boolean;
  limit?: number;
  name?: string;
  region?: string;
  documentType?: string;
  documentNumber?: number;
};
export const fetchDoctors = async (
  token: string,
  filter?: FilterDoctorList
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/doctors${toQueryString(
        filter ?? { limit: 1000 }
      )}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.items as Doctor[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc2");
      return Promise.reject(err);
    });
};

export const deleteDoctorFromClinic = async (token: string, doctID: string) => {
  return axios
    .delete(`${process.env.REACT_APP_API_PATH}/clinic-doctors/${doctID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc4");
      return Promise.reject(err);
    });
};

export type AddDoctorToClinicRequest = {
  doctID: string;
  doctorPhone: string;
  specialties?: number[];
  preferences?: {
    hideProfileInProcedure?: boolean;
  };
};
export const addDoctorToClinic = async (
  token: string,
  doctor: AddDoctorToClinicRequest
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/clinic-doctors`, doctor, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc5");
      return Promise.reject(new Error(err));
    });
};

export const fetchDoctorPublicProfile = async (username: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctor-profiles/${username}`)
    .then((r) => {
      return r.data.data.item as DoctorPublicProfile;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "Dpub1");
      return Promise.reject(new Error(err));
    });
};

export const fetcClinicDoctors = async (
  token: string,
  filter?: ClinicDoctorListFilter
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-doctors${f}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.items as ClinicDoctor[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc6");
      return Promise.reject(new Error(err));
    });
};

export const fetchClinicDoctor = async (token: string, doctID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-doctors/${doctID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc7");
      return Promise.reject(new Error(err));
    });
};

export const addClinicDoctorPatient = async (
  token: string,
  data: { doctID: string; patiID: string }
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/clinic-doctors-patient`, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as DoctorClinicPatient;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc8");
      return Promise.reject(new Error(err));
    });
};

export const fetchClinicDoctorPatients = async (
  token: string,
  filter?: { doctID?: string; patiID?: string }
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-doctors-patient${f}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as DoctorClinicPatient[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc10");
      return Promise.reject(new Error(err));
    });
};

export const fetchDoctorLicense = async (
  token: string,
  filter?: { prescriptionAllowedLicenses?: boolean }
) => {
  const f = filter ? toQueryString(filter) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/doctor-license${f}`, {
      headers: { Authorization: token },
    })
    .then((r) => {
      return r.data.data.items as DoctorLicense[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "doc11");
      return Promise.reject(new Error(err));
    });
};
