import axios from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Clinic,
  ClinicPreference,
  ClinicPreferenceForm,
  ClinicFilter,
} from "@udok/lib/api/models";

export const fetchClinicProfile = async (slug: string) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_PATH}/clinic/${slug}`)
    .then((r) => {
      return r.data.data.item as Clinic;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli1");
      return Promise.reject(err);
    });
};

export const updateClinicProfile = async (token: string, data: Clinic) => {
  return axios
    .put(`${process.env.REACT_APP_API_PATH}/clinics`, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as Clinic;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli2");
      return Promise.reject(err);
    });
};

export const fetchClinicProfileByClinID = async (clinID: string) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_PATH}/profile/clinic/${clinID}`)
    .then((r) => {
      return r.data.data.item as Clinic;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli3");
      return Promise.reject(err);
    });
};

export const createClinicPreference = async (
  token: string,
  pref: ClinicPreferenceForm
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/clinic-preferences`, pref, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicPreference;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli4");
      return Promise.reject(new Error(err));
    });
};

export const fetchClinicPreference = async (token: string, prefID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-preferences/${prefID}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicPreference;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli5");
      return Promise.reject(new Error(err));
    });
};

export const fetchListClinicPreference = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-preferences`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ClinicPreference[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "cli6");
      return Promise.reject(new Error(err));
    });
};

export function fetchClinics(apiToken: string, f?: ClinicFilter) {
  const filter = f ? toQueryString(f) : "";
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinics${filter}`, {
      headers: {
        Authorization: apiToken,
      },
    })
    .then((r) => {
      return r?.data?.data?.items as Clinic[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "sch16");
      return Promise.reject(err);
    });
}
