import React from "react";
import { SelectProps } from "@material-ui/core";
import { AppointmentType } from "@udok/lib/api/models";
import {
  getAppointmentTypes,
  loadOneAppointmentType,
} from "ducks/appointmentType";
import Selector from "components/Selector";
import { connect } from "react-redux";

export interface ScheduleAppoOptionsProps {
  onChange?: (id: string | undefined) => void;
  loadOneAppointmentType: (aptyID: string) => Promise<void>;
  Idlist: string[];
  appoTypes: AppointmentType[];
  error?: boolean;
  multiple?: boolean;
  value?: string | string[];
  label?: string;
  style?: React.CSSProperties;
  fullWidth?: SelectProps["fullWidth"];
  placeholder?: string;
  errorMessage?: string;
  disabled?: boolean;
  variant?: "outlined";
  margin?: "dense" | "none" | "normal";
}

const ScheduleAppoOptions = (props: ScheduleAppoOptionsProps) => {
  const {
    onChange,
    loadOneAppointmentType,
    Idlist,
    appoTypes,
    style,
    error,
    multiple,
    value,
    placeholder,
    label,
    fullWidth,
    errorMessage,
    disabled,
    variant,
    margin,
  } = props;
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    Promise.all(Idlist.map((id) => loadOneAppointmentType(id)))
      .catch(console.warn)
      .finally(() => {
        setLoading(false);
      });
  }, [Idlist, loadOneAppointmentType]);

  const val = multiple
    ? Array.isArray(value)
      ? value
      : value
      ? [value]
      : []
    : String(value ?? "");

  let listItens = appoTypes
    .map((l) => {
      return {
        label: l.name,
        value: l.aptyID,
      };
    })
    .concat([
      {
        label: "Padrão",
        value: "default",
      },
    ]);

  return (
    <Selector
      style={style}
      multiple={multiple}
      fullWidth={fullWidth}
      list={listItens}
      loading={loading}
      placeholder={placeholder}
      error={error}
      value={val}
      onChange={onChange}
      errorMessage={errorMessage}
      label={label}
      disabled={disabled}
      variant={variant}
      margin={margin}
    />
  );
};

export default connect(getAppointmentTypes, (dispatch: any) => ({
  loadOneAppointmentType: (aptyID: string) =>
    dispatch(loadOneAppointmentType(aptyID)),
}))(ScheduleAppoOptions);
