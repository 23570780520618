import React from "react";
import Viewer, {
  ViewerOptions,
} from "@toast-ui/editor/dist/toastui-editor-viewer";
import "@toast-ui/editor/dist/toastui-editor-viewer.css";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MarkdownTheme from "@udok/lib/components/Markdown/MarkdownTheme";
import clsx from "clsx";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      "& > .tui-editor-contents": {
        ...MarkdownTheme.root,
        fontWeightBold: 700,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
      },
      "& *": {
        overflowWrap: "break-word",
      },
      "& > .tui-editor-contents h1, & > .tui-editor-contents h2": {
        border: "none",
      },
      "& > .tui-editor-contents h1": {
        ...MarkdownTheme.h1,
      },
      "& > .tui-editor-contents h2": {
        ...MarkdownTheme.h2,
      },
      "& > .tui-editor-contents h3": {
        ...MarkdownTheme.h3,
      },
      "& > .tui-editor-contents h4": {
        ...MarkdownTheme.h4,
      },
      "& > .tui-editor-contents h5": {
        ...MarkdownTheme.h5,
      },
      "& > .tui-editor-contents h6": {
        ...MarkdownTheme.h6,
      },
      "& > .tui-editor-contents p": {
        ...MarkdownTheme.body1,
      },
      "& > .tui-editor-contents a": {
        ...MarkdownTheme.link,
      },
    },
  })
);

let viewerInst: any = null;

interface MarkdownViwerProps
  extends Omit<ViewerOptions, "el" | "initialValue"> {
  markdown: string;
  className?: string;
}

const MarkdownViwer = (props: MarkdownViwerProps) => {
  const { markdown, className, ...others } = props;
  const rootEl = React.useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const bindEventHandlers = React.useCallback(() => {
    Object.keys(props)
      .filter((key) => /^on[A-Z][a-zA-Z]+/.test(key))
      .forEach((key) => {
        const eventName = key[2].toLowerCase() + key.slice(3);
        viewerInst?.off?.(eventName);
        viewerInst?.on?.(eventName, props[key as keyof MarkdownViwerProps]);
      });
  }, [props]);

  React.useEffect(() => {
    if (rootEl?.current) {
      viewerInst = new Viewer({
        el: rootEl.current,
        initialValue: markdown,
        ...others,
      });

      bindEventHandlers();
    }
  }, [rootEl, markdown, others, bindEventHandlers]);

  return <div className={clsx(classes.root, className)} ref={rootEl} />;
};

export default MarkdownViwer;
