import { createReducer } from "@udok/lib/internal/store";
import { createSelector } from "reselect";
import { Permission as pm } from "@udok/lib/internal/constants";
import { RootState } from "ducks/state";
import Icons from "@udok/lib/components/Icon";
import {
  UdokProTawkHelpOption,
  getUdokProHelpURL,
  UdokSupportContactNumber,
} from "@udok/lib/internal/constants";
import { generateWhatsappURL } from "@udok/lib/internal/util";

export interface MenuItem {
  title: string;
  description?: string;
  to: string;
  icon: keyof typeof Icons;
  illustration?: string;
  permissions: pm[];
}
export interface MenuView {
  title?: string;
  to?: string;
  icon?: keyof typeof Icons;
  permissions?: pm[];
  children: MenuItem[];
}

export type MenuSection = keyof Omit<InitialState, "helpMenu">;

export interface HelpMenuItem {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  cardImage?: string;
  actionUrl: string;
}

export interface InitialState {
  lateralMenu: MenuView[];
  prescriptionMenu: MenuView[];
  quickAccess: MenuView[];
  finances: MenuView[];
  home: MenuView[];
  patients: MenuView[];
  profile: MenuView[];
  helpMenu: HelpMenuItem[];
}

// Reducers
export const initialState: InitialState = {
  lateralMenu: [
    {
      title: "Página inicial",
      permissions: [pm.Admin],
      to: "/admin/explore",
      icon: "House",
      children: [],
    },
    {
      title: "Agenda",
      to: "/admin/calendar",
      icon: "Calendar",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Agendamentos",
          to: "/admin/appointments",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Configuração de horários",
          to: "/admin/schedule",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Bloqueio de horários",
          to: "/admin/scheduleLock",
          icon: "LockOpenOutlined",
          illustration: "CalendarBlock",
          description: "Configure bloqueios para os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Gerar cupom",
          to: "/admin/voucher",
          icon: "ConfirmationNumberOutlined",
          permissions: [pm.Admin, pm.Agenda],
        },
      ],
    },
    {
      title: "Receituário digital",
      to: "/admin/document",
      icon: "Prescription",
      permissions: [pm.Admin],
      children: [
        {
          title: "Prescrição",
          to: "/admin/document/create/prescription",
          icon: "LocalHospital",
          illustration: "Prescription",
          permissions: [pm.Admin],
        },
        {
          title: "Atestado Médico",
          to: "/admin/document/create/medical_certificate",
          icon: "LocalHospital",
          illustration: "MedicalCertificate",
          permissions: [pm.Admin],
        },
        {
          title: "Relatório Médico",
          to: "/admin/document/create/medical_report",
          icon: "LocalHospital",
          illustration: "MedicalReport",
          permissions: [pm.Admin],
        },
        {
          title: "Solicitação de Exames",
          to: "/admin/document/create/exam",
          icon: "LocalHospital",
          illustration: "Exam",
          permissions: [pm.Admin],
        },
        {
          title: "Customizar receituário",
          to: "/admin/prescriptionlayout",
          icon: "LocalHospital",
          illustration: "",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Pacientes",
      to: "/admin/patients",
      icon: "Examination",
      permissions: [pm.Admin],
      children: [
        {
          title: "Enviar convite",
          to: "/admin/sendinvitation",
          icon: "LocalHospital",
          illustration: "Invite",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Mensagens",
      to: "/admin/conversations",
      icon: "Chat",
      permissions: [pm.Admin],
      children: [
        {
          title: "Respostas rápidas",
          to: "/admin/quick-response",
          icon: "Chat",
          illustration: "Chat",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Minhas finanças",
      to: "/admin/finances",
      icon: "DollarSingle",
      permissions: [pm.Admin],
      children: [
        {
          title: "Conta bancária",
          to: "/admin/finances/bank-accounts",
          icon: "DescriptionOutlined",
          illustration: "DocumentEdit",
          description: "Crie e edite formulários de pré atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Cartões de credito",
          to: "/admin/finances/credit-card",
          icon: "Bank",
          illustration: "CCGeneric",
          description: "Cadastre seu cartão de credito",
          permissions: [pm.Admin],
        },
        {
          title: "Minha assinatura",
          to: "/admin/finances/subscription",
          icon: "CardMembershipSharp",
          illustration: "Subscription",
          description: "Gerenciar planos contratados",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Configurações",
      to: "/admin/settings",
      icon: "Config",
      permissions: [pm.Admin],
      children: [
        {
          title: "Formulários pré atendimento",
          to: "/admin/documents",
          icon: "DescriptionOutlined",
          illustration: "DocumentEdit",
          description: "Crie e edite formulários de pré atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Arquivos de pacientes",
          to: "/admin/collectdocuments",
          icon: "Collections",
          illustration: "CollectUpload",
          description:
            "Configure o carregamento de documentos?(ex: exames, documentos, guias, etc)",
          permissions: [pm.Admin],
        },
        {
          title: "Tipos de atendimentos",
          to: "/admin/appointmentype",
          icon: "EventNoteOutlined",
          illustration: "AddAppoType",
          description:
            "Configure os tipos de atendimentos?(ex: acompanhamento, primeiro atendimento, triagem, etc)",
          permissions: [pm.Admin],
        },
        {
          title: "Locais de atendimento",
          to: "/admin/locations",
          icon: "PinDropOutlined",
          illustration: "Location",
          description: "Gerencie os endereços dos locais de atendimento",
          permissions: [pm.Admin],
        },
      ],
    },
    {
      title: "Meu Perfil",
      icon: "User",
      to: "/admin/user-profile",
      permissions: [pm.Admin],
      children: [
        {
          title: "Certificado digital",
          to: "/admin/certificates",
          icon: "CardMembershipSharp",
          illustration: "Certificate",
          description: "Gerenciar certificados digitais",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  prescriptionMenu: [
    {
      title: "Receituário",
      children: [
        {
          title: "Prescrição",
          to: "/admin/document/create/prescription",
          icon: "LocalHospital",
          illustration: "PrescriptionControlled",
          permissions: [pm.Admin],
        },
        {
          title: "Atestado Médico",
          to: "/admin/document/create/medical_certificate",
          icon: "LocalHospital",
          illustration: "MedicalCertificate",
          permissions: [pm.Admin],
        },
        {
          title: "Relatório Médico",
          to: "/admin/document/create/medical_report",
          icon: "LocalHospital",
          illustration: "MedicalReport",
          permissions: [pm.Admin],
        },
        {
          title: "Solicitação de Exames",
          to: "/admin/document/create/exam",
          icon: "LocalHospital",
          illustration: "Exam",
          permissions: [pm.Admin],
        },
        {
          title: "Documento livre",
          to: "/admin/document/create/free_document",
          icon: "LocalHospital",
          illustration: "OtherDocuments",
          permissions: [pm.Admin],
        },
        {
          title: "Customizar receituário",
          to: "/admin/prescriptionlayout",
          icon: "NoteAddOutlined",
          illustration: "TemplateEditor",
          description: "Configure o modelo utilizado nos documentos prescritos",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  home: [
    {
      title: "Tela inicial",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Agenda",
          to: "/admin/explore/calendar",
          icon: "CalendarToday",
          illustration: "Calendar",
          description:
            "Agende e visualize suas consultas, exames e procedimentos",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Receituário digital",
          to: "/admin/document",
          icon: "LocalHospital",
          illustration: "DocumentMedical",
          description: "Crie e assine documentos médicos.",
          permissions: [pm.Admin],
        },
        {
          title: "Pacientes",
          to: "/admin/explore/patients",
          icon: "Examination",
          illustration: "Patient",
          description: "Informações de pacientes.",
          permissions: [pm.Admin],
        },
        {
          title: "Meu Perfil",
          to: "/admin/explore/profile",
          icon: "AccountCircle",
          illustration: "MedicalComputer",
          description: "Visualizar informações do perfil",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  patients: [
    {
      title: "Tela inicial",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Cadastros",
          to: "/admin/patients",
          icon: "Examination",
          illustration: "Patient",
          description: "Informações de pacientes.",
          permissions: [pm.Admin],
        },
        {
          title: "Mensagens",
          to: "/admin/conversations",
          icon: "Message",
          illustration: "ChatMessages",
          description: "Comunicação com pacientes.",
          permissions: [pm.Admin],
        },
        {
          title: "Cupom de agendamento",
          to: "/admin/voucher",
          icon: "ConfirmationNumberOutlined",
          illustration: "Voucher",
          description: "Dispensar cobrança de agendamentos",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Enviar convite",
          to: "/admin/sendinvitation",
          icon: "LocalHospital",
          illustration: "Invite",
          description: "Convite para paciente",
          permissions: [pm.Admin],
        },
        {
          title: "Formulários pré atendimento",
          to: "/admin/documents",
          icon: "DescriptionOutlined",
          illustration: "DocumentEdit",
          description: "Crie e edite formulários de pré atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Arquivos de pacientes",
          to: "/admin/collectdocuments",
          icon: "Collections",
          illustration: "CollectUpload",
          description:
            "Configure o carregamento de documentos?(ex: exames, documentos, guias, etc)",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  profile: [
    {
      title: "Meu Perfil",
      icon: "User",
      permissions: [pm.Admin],
      children: [
        {
          title: "Certificado digital",
          to: "/admin/certificates",
          icon: "CardMembershipSharp",
          illustration: "Certificate",
          description: "Gerenciar certificados digitais",
          permissions: [pm.Admin],
        },
        {
          title: "Meu Perfil",
          to: "/admin/user-profile",
          icon: "AccountCircle",
          illustration: "MedicalComputer",
          description: "Visualizar informações do perfil",
          permissions: [pm.Admin],
        },
        {
          title: "Minhas finanças",
          to: "/admin/finances",
          icon: "DollarSingle",
          illustration: "Budget",
          description: "Informações financeiras",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  quickAccess: [
    {
      title: "Gestão de agenda",
      permissions: [pm.Admin, pm.Agenda],
      children: [
        {
          title: "Calendário",
          to: "/admin/calendar",
          icon: "CalendarToday",
          illustration: "Calendar",
          description:
            "Agende e visualize suas consultas, exames e procedimentos",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Configuração de horários",
          to: "/admin/schedule",
          icon: "ListAlt",
          illustration: "CalendarWriteColor",
          description: "Configure os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
        {
          title: "Bloqueio de horários",
          to: "/admin/scheduleLock",
          icon: "LockOpenOutlined",
          illustration: "CalendarBlock",
          description: "Configure bloqueios para os horários de atendimento",
          permissions: [pm.Admin, pm.Agenda],
        },
      ],
    },
    {
      title: "Configuração de atendimentos",
      permissions: [pm.Admin],
      children: [
        {
          title: "Tipos de atendimentos",
          to: "/admin/appointmentype",
          icon: "EventNoteOutlined",
          illustration: "AddAppoType",
          description:
            "Configure os tipos de atendimentos?(ex: acompanhamento, primeiro atendimento, triagem, etc)",
          permissions: [pm.Admin],
        },
        {
          title: "Locais de atendimento",
          to: "/admin/locations",
          icon: "PinDropOutlined",
          illustration: "Location",
          description: "Gerencie os endereços dos locais de atendimento",
          permissions: [pm.Admin],
        },
        {
          title: "Mais configurações",
          to: "/admin/settings",
          icon: "Config",
          illustration: "Settings",
          description: "Mais configurações",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  finances: [
    {
      title: "Minhas finanças",
      permissions: [pm.Admin],
      children: [
        {
          title: "Conta bancária",
          to: "/admin/finances/bank-accounts",
          icon: "Bank",
          illustration: "BankColor",
          description: "Cadastre sua conta bancária para recebimentos",
          permissions: [pm.Admin],
        },
        {
          title: "Cartões de credito",
          to: "/admin/finances/credit-card",
          icon: "Bank",
          illustration: "CCGeneric",
          description: "Cadastre seu cartão de credito",
          permissions: [pm.Admin],
        },
        {
          title: "Minha assinatura",
          to: "/admin/finances/subscription",
          icon: "CardMembershipSharp",
          illustration: "Subscription",
          description: "Gerenciar planos contratados",
          permissions: [pm.Admin],
        },
      ],
    },
  ],
  helpMenu: [
    {
      title: "Como gerenciar minha agenda?",
      description: "Conheça todas as opções de configuração de agenda no Udok",
      actionUrl: getUdokProHelpURL(UdokProTawkHelpOption.generalUdokPro),
    },
    {
      title: "Como prescrever na plataforma?",
      description: "Aprenda a melhor maneira de criar seus documentos digitais",
      actionUrl: getUdokProHelpURL(UdokProTawkHelpOption.generalDigitalReceipt),
    },
    {
      title: "Como editar o perfil publico?",
      description:
        "Entenda como funciona o Udok Digital e a melhor maneira de atualizar o seu perfil",
      actionUrl: getUdokProHelpURL(UdokProTawkHelpOption.setUpPublicProfile),
    },
    {
      title: "Suporte via WhatsApp",
      description: `Não encontrou a resposta para sua duvida?\nEntre em contato pelo WhatsApp`,
      actionUrl: generateWhatsappURL(UdokSupportContactNumber),
    },
  ],
};

export const Reducer = createReducer(initialState, {});

// Selectors
const mainSelector = (state: RootState) => state.menu;

export const getSidebarMenu = createSelector([mainSelector], (state) => {
  return {
    menu: state.lateralMenu,
  };
});

export const getPrescriptionTypeList = createSelector(
  [mainSelector],
  (state) => {
    return {
      menu: state.prescriptionMenu,
    };
  }
);

export const getHelpMenuOptions = createSelector([mainSelector], (state) => {
  return {
    menu: state.helpMenu,
  };
});

export const getFinancesMenu = createSelector([mainSelector], (state) => {
  return {
    menu: state.finances,
  };
});

export const quickAccessView = createSelector([mainSelector], (state) => {
  return {
    menu: state.quickAccess,
  };
});

export const makeSectionView = (section: MenuSection) =>
  createSelector([mainSelector], (state) => {
    return {
      menu: state[section],
    };
  });
