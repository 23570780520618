import * as React from "react";
import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import "@toast-ui/editor/dist/i18n/pt-br";
import { Editor } from "@toast-ui/react-editor";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MarkdownTheme } from "@udok/lib/components/Markdown";
import "./styles.css";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      "&> :first-child": {
        width: "100%",
      },
      "& .tui-editor-contents": {
        ...MarkdownTheme.root,
        fontWeightBold: 700,
        fontWeightLight: 300,
        fontWeightMedium: 500,
        fontWeightRegular: 400,
      },
      "& .tui-editor-contents > h1, & .tui-editor-contents > h2": {
        border: "none",
      },
      "& .tui-editor-contents > h1": {
        ...MarkdownTheme.h1,
      },
      "& .tui-editor-contents > h2": {
        ...MarkdownTheme.h2,
      },
      "& .tui-editor-contents > h3": {
        ...MarkdownTheme.h3,
      },
      "& .tui-editor-contents > h4": {
        ...MarkdownTheme.h4,
      },
      "& .tui-editor-contents > h5": {
        ...MarkdownTheme.h5,
      },
      "& .tui-editor-contents > h6": {
        ...MarkdownTheme.h6,
      },
      "& .tui-editor-contents > p": {
        ...MarkdownTheme.body1,
      },
      "& .tui-editor-contents a": {
        ...MarkdownTheme.link,
      },
    },
  })
);

export type ToolbarItems =
  | "heading"
  | "bold"
  | "italic"
  | "strike"
  | "divider"
  | "ul"
  | "ol"
  | "task"
  | "table"
  | "image";

const DefaultItens = [
  "bold",
  "italic",
  "strike",
  "divider",
  "ul",
  "ol",
  "task",
  "table",
  "image",
];

export function replaceHtmltags(markdown: string) {
  const rm = markdown
    .replace("<br>", "\n")
    .replace("<del>", " ~~")
    .replace("</del>", "~~ ")
    .replace("<s>", " ~~")
    .replace("</s>", "~~ ")
    .replace("<i>", " *")
    .replace("</i>", "* ")
    .replace("<b>", " **")
    .replace("</b>", "** ")
    .replace("\\<", "&#60;");
  var doc = new DOMParser().parseFromString(rm, "text/html");
  return doc.body.textContent ?? "";
}

function removeHTMLTagsFromText(html: string, deleaTags: string[]) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  deleaTags.forEach((tag) => {
    let docTags = doc.getElementsByTagName(tag);
    [...Array.from(docTags)].forEach((e) => {
      e.outerHTML = e?.textContent ?? "";
    });
  });
  return doc.body.innerHTML;
}

export type MarkdownProps = {
  value?: string;
  placeholder?: string;
  toolbarItems?: ToolbarItems[];
  onChange?: (value: string) => void;
};
var Key = "markdown-editor";
const Markdown = React.forwardRef(
  (props: MarkdownProps, ref: React.Ref<any>) => {
    const { placeholder, value = "", toolbarItems, onChange } = props;
    const [mdValue, setMdValue] = React.useState("");
    const classes = useStyles();
    const editorRef = React.useRef<Editor>(null);

    React.useEffect(() => {
      if (!!value && !mdValue) {
        Key += "_" + Math.floor(Math.random() * 10);
        setMdValue(value.trim());
      }
    }, [value]);

    const handleChange = React.useCallback(
      (e) => {
        const editor = editorRef?.current?.getInstance?.();
        const editorValue = replaceHtmltags(editor?.getMarkdown?.() ?? "");
        setMdValue((md) => {
          if (md != editorValue) {
            onChange?.(editorValue);
          }
          return editorValue;
        });
      },
      [editorRef, onChange]
    );

    return (
      <span ref={ref} className={classes.root}>
        <Editor
          key={Key}
          ref={editorRef}
          initialValue={value}
          previewStyle="vertical"
          height="100%"
          initialEditType="wysiwyg"
          language="pt"
          placeholder={placeholder}
          onChange={handleChange}
          toolbarItems={toolbarItems ?? DefaultItens}
          customHTMLSanitizer={(html) =>
            removeHTMLTagsFromText(html, ["a", "span"])
          }
        />
      </span>
    );
  }
);

export default Markdown;
