import axios, { CancelTokenSource } from "axios";
import { deriveErrorMessage, toQueryString } from "@udok/lib/internal/util";
import {
  Tuss,
  FilterTussList,
  ExamProcedure,
  ExamProcedureFilter,
  ClinicExamProcedureDoctor,
} from "@udok/lib/api/models";

const makeCanceableSearchProcedures = () => {
  let cancel: CancelTokenSource | undefined;

  return async (filter?: ExamProcedureFilter) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    const f = filter ? toQueryString(filter) : "";
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/search/exams-procedures${f}`, {
        headers: {
          cancelToken: cancel.token,
        },
      })
      .then((r) => {
        return r.data.data.items as ExamProcedure[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as ExamProcedure[];
        }
        const err = deriveErrorMessage(e, "expr1");
        return Promise.reject(new Error(err));
      });
  };
};

export const searchProcedures = makeCanceableSearchProcedures();

export const fetchProcedure = async (exprID: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/exams-procedures/${exprID}`)
    .then((r) => {
      return r.data.data.item as ExamProcedure;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr2");
      return Promise.reject(new Error(err));
    });
};

export const createClinicExamsProcedures = async (
  token: string,
  data: { exprID: string; doctors: string[] }
) => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/clinic-exams-procedures`, data, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.item as ClinicExamProcedureDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr3");
      return Promise.reject(new Error(err));
    });
};

export const updateClinicExamsProcedures = async (
  token: string,
  data: { exprID: string; doctors: string[] }
) => {
  return axios
    .put(
      `${process.env.REACT_APP_API_PATH}/clinic-exams-procedures/${data.exprID}`,
      data,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ClinicExamProcedureDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr4");
      return Promise.reject(new Error(err));
    });
};

export const getClinicExamsProcedures = async (
  token: string,
  exprID: string
) => {
  return axios
    .get(
      `${process.env.REACT_APP_API_PATH}/clinic-exams-procedures/${exprID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ClinicExamProcedureDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr5");
      return Promise.reject(new Error(err));
    });
};

export const listClinicExamsProcedures = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/clinic-exams-procedures`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as ClinicExamProcedureDoctor[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr6");
      return Promise.reject(new Error(err));
    });
};

export const deleteClinicExamsProcedures = async (
  token: string,
  exprID: string
) => {
  return axios
    .delete(
      `${process.env.REACT_APP_API_PATH}/clinic-exams-procedures/${exprID}`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r) => {
      return r.data.data.item as ClinicExamProcedureDoctor;
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr7");
      return Promise.reject(new Error(err));
    });
};

export const registerTussUsage = async (token: string, tussCode: string) => {
  return axios
    .post(
      `${process.env.REACT_APP_API_PATH}/tuss-usages`,
      { tussCode },
      {
        headers: { Authorization: token },
      }
    )
    .catch(() => console.warn);
};

const makeCanceableTussFetch = () => {
  let cancel: CancelTokenSource | undefined;

  return async (token: string, filter: FilterTussList) => {
    if (cancel) {
      cancel.cancel();
    }
    cancel = axios.CancelToken.source();
    return axios
      .get(`${process.env.REACT_APP_API_PATH}/tuss${toQueryString(filter)}`, {
        cancelToken: cancel.token,
        headers: {
          Authorization: token,
        },
      })
      .then((r) => {
        return r.data.data.items as Tuss[];
      })
      .catch((e) => {
        if (axios.isCancel(e)) {
          return [] as Tuss[];
        }
        const err = deriveErrorMessage(e, "expr8");
        return Promise.reject(new Error(err));
      });
  };
};

export const fetchAllTuss = makeCanceableTussFetch();

export const fetchRecentTuss = async (token: string) => {
  return axios
    .get(`${process.env.REACT_APP_API_PATH}/tuss-usages`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r) => {
      return r.data.data.items as Tuss[];
    })
    .catch((e) => {
      const err = deriveErrorMessage(e, "expr9");
      return Promise.reject(new Error(err));
    });
};
